import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ConfigService } from "../services/config.service";

@Injectable({
    providedIn: 'root'
})

class PermissionsService {
    constructor(
        private router: Router,
        private authService: AuthService,
        private configService: ConfigService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authService.getUserAccessToken() || state.url.includes('/directory') || this.configService.isPreview) {
            return true;
        }

        this.router.navigate(['/public']);
        // Keep line below here for future, when behaviour for login is confirmed
        // this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
        // ^^ When user is prompted to login and does it, they will be redirected to where they left off.

        return false;
    }
}

export const userAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    return inject(PermissionsService).canActivate(next, state);
};