import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { BookmarkComponent } from "../../components/bookmark/bookmark.component";
import { LikeComponent } from "../../components/like/like.component";
import { VideoLanguageComponent } from "../../components/video-language/video-language.component";
import { FolderIcon } from "../../icons";
import { PersonListComponent } from "../../components/person-list/person-list.component";
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { PersonListModel } from '../../models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { VideoPlayState } from '../../enum/video-playState.enum';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-video-page',
  standalone: true,
  templateUrl: './video-page.component.html',
  styleUrl: './video-page.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, BookmarkComponent, LikeComponent, VideoLanguageComponent, FolderIcon, PersonListComponent, TranslateModule]
})

export class VideoPageComponent implements OnInit, AfterViewInit, OnDestroy {
  parentIdentifier!: string;
  currentUrlArr!: string[];
  switchParentContentType!: string;
  switchChildContentType!: string;
  videoIdentifier!: string;
  video_src!: SafeResourceUrl;
  baristaData: PersonListModel[] = [];
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/home',
      label: 'Home'
    }
  ]
  videoDocuments: { link: string, display_name: string }[] = [];
  videoData = {
    content_id: '',
    headline: '',
    descriptions: '',
    top_subtitle: '',
    is_live: true
  };

  // Video Analytics variable
  rawVideoSrc!: string;
  player: any;
  setPlayerInterval: any;
  videoAnalyticsInterval: any;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) { }

  ngAfterViewInit() {
    if (!this.configService.isPreview) {
      this.loadYouTubeAPI();
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.setPlayerInterval);
    clearInterval(this.videoAnalyticsInterval);
    this.videoAnalyticsInterval = null;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.videoIdentifier = params['contentId'];
      this.parentIdentifier = params['pId'];

      this.currentUrlArr = this.router.url.toUpperCase().split('/').filter(Boolean);
      const segmentUrl = this.router.url.split('/');

      // Construct breadcumb link
      if (this.currentUrlArr.includes('MY-BOOKMARK')) {
        segmentUrl.shift(); // remove first item in array
        this.breadcrumbsData[0].link = '/my-bookmark';
      } else {
        segmentUrl.pop();
        segmentUrl.pop();
        const updatedUrl = segmentUrl.join('/');
        this.breadcrumbsData[0].link = updatedUrl;
      }

      this.getContentType(this.currentUrlArr);

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.videoIdentifier, this.switchChildContentType, this.router.url);
      }

      // Construct breadcumb label
      this.translation.get(['MY_BOOKMARK.HEADER', 'GENERAL.MENU.LEARNING_CENTRE', 'HOME.EXPLORE.WORKSHOP', 'HOME.EXPLORE.COMPETITIONS', 'HOME.EXPLORE.EVENT']).subscribe({
        next: res => {
          switch (true) {
            case this.currentUrlArr.includes('MY-BOOKMARK'):
              this.breadcrumbsData[0].label = res['MY_BOOKMARK.HEADER'];
              break;
            case this.currentUrlArr.includes('LEARN'):
              this.breadcrumbsData[0].label = res['GENERAL.MENU.LEARNING_CENTRE'];
              break;
            case this.currentUrlArr.includes('EVENTS'):
              this.breadcrumbsData[0].label = res['HOME.EXPLORE.EVENT'];
              break;
            case this.currentUrlArr.includes('COMPETITIONS'):
              this.breadcrumbsData[0].label = res['HOME.EXPLORE.COMPETITIONS'];
              break;
            case this.currentUrlArr.includes('WORKSHOPS'):
              this.breadcrumbsData[0].label = res['HOME.EXPLORE.WORKSHOP'];
              break;
            default:
              break;
          }
        },
        complete: () => {
          // Specific Label
          if (this.parentIdentifier) {

            this.contentService.postContent(this.switchParentContentType, this.parentIdentifier).subscribe({
              next: res => {
                this.breadcrumbsData[0].label = res[0].title;
              },
              error: err => {
                console.log(err);
              }
            });
          }
        }
      })
    });

    // Get Video Data
    this.contentService.postContent(this.switchChildContentType, this.videoIdentifier).subscribe({
      next: res => {
        const data = res[0];
        // Page Title
        this.titleService.setTitle(data.videoTitle);

        // Video Data
        this.videoData = {
          content_id: data.identifier,
          headline: data.videoTitle,
          descriptions: data.videoDescriptions,
          top_subtitle: data.videoTopSubtitle,
          is_live: this.checkIsLive(data.startDateTime, data.endDateTime)
        }

        // Video Analytics
        this.rawVideoSrc = data.videoUrl;

        // Sanitize video source for iframe
        this.sanitizeVideoUrl(res[0].videoUrl);

        // Document Data
        if (res[0].pdfDocuments) {
          const pdfArr = JSON.parse(res[0].pdfDocuments);
          pdfArr.forEach((item: any) => {
            this.videoDocuments.push({
              link: item.src, // Manual for now. TBC
              display_name: item.name
            })
          });
        }

        // Person Data
        const selectedPersonList = res[0].selectPerson;
        if (selectedPersonList) {
          selectedPersonList.forEach((person: any) => {
            this.baristaData.push({
              content_id: person.identifier,
              content_type: person.contentType,
              image_src: person.profileImage,
              name: person.title,
              title: person.personTitle ?? '',
              market: person.market.toUpperCase() !== 'NOT APPLICABLE' ? person.market : '',
              bio_text: person.bioText ?? '',
              bio_video_link: person.bioVideo ?? '',
              bio_type: person.bioType
            })
          });
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  checkIsLive(start_date: number, end_date: number): boolean {
    const now = new Date().getTime();
    const timestampStart = new Date(start_date).getTime();
    const timestampEnd = new Date(end_date).getTime();

    if (timestampStart <= now && timestampEnd >= now) {
      return true;
    } else {
      return false;
    }
  }

  getContentType(urlArr: string[]) {
    // Switch case for CMS SBUX Content Type
    switch (true) {
      case urlArr.includes('LEARN'):
        this.switchParentContentType = 'SbuxLearningTopic';
        this.switchChildContentType = 'SbuxLearningLesson';
        // Add Content Analytic
        break;

      case urlArr.includes('EVENTS'):
        this.switchChildContentType = 'SbuxEventVideo';

        if (this.currentUrlArr.includes('ACTIVITIES')) {
          this.switchParentContentType = 'SbuxEventActivity';
        } else {
          this.switchParentContentType = 'SbuxEvent';
        }
        break;

      case urlArr.includes('COMPETITIONS'):
        this.switchParentContentType = 'SbuxCompetition';
        this.switchChildContentType = 'SbuxCompetitionVideo';
        break;

      case urlArr.includes('WORKSHOPS'):
        this.switchParentContentType = '';
        this.switchChildContentType = 'SbuxWorkshopArchive';
        break;

      default:
        break;
    }
  }

  sanitizeVideoUrl(videoUrl: string) {
    const vidUrl = this.contentService.formatVideoUrl(videoUrl) + '?enablejsapi=1';
    this.video_src = this.sanitizer.bypassSecurityTrustResourceUrl(vidUrl);
  }

  sanitizeStyle(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }

  loadYouTubeAPI() {
    (window as any).onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady();
  }

  onYouTubeIframeAPIReady() {
    this.setPlayerInterval = setInterval(() => {
      this.player = new (window as any).YT.Player('youtube-iframe', {
        events: {
          'onReady': (e: any) => this.onPlayerReady(e),
          'onStateChange': (e: any) => this.onPlayerStateChange(e)
        }
      });
      if (this.player) {
        clearInterval(this.setPlayerInterval);
      }
    }, 1000)
  }

  onPlayerReady(event: any) {
    clearInterval(this.setPlayerInterval);
  }

  onPlayerStateChange(event: any) {
    clearInterval(this.setPlayerInterval);
    if (event.target.playerInfo.playerState === VideoPlayState.PLAYING || event.target.playerInfo.playerState === VideoPlayState.BUFFERING) {
      if (!this.videoAnalyticsInterval) {
        this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.switchChildContentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        this.videoAnalyticsInterval = setInterval(() => {
          this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.switchChildContentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
        }, 30000)
      }
    } else if (event.target.playerInfo.playerState === VideoPlayState.ENDED || event.target.playerInfo.playerState === VideoPlayState.PAUSED) {
      this.analyticsService.createVideoAnalytic(this.videoIdentifier, this.switchChildContentType, event.target.playerInfo.playerState, this.rawVideoSrc, event.target.playerInfo.currentTime, event.target.playerInfo.duration, event.target.playerInfo.currentTimeLastUpdated_);
      clearInterval(this.videoAnalyticsInterval);
      this.videoAnalyticsInterval = null;
    }
  }
}
