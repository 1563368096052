import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner2Component } from "../../components/image-banner-2/image-banner-2.component";
import { PersonGridListComponent } from "../../components/person-grid-list/person-grid-list.component";
import { ContentService } from '../../services/content.service';
import { PersonListModel } from '../../models';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-competition-steering-committee',
  standalone: true,
  imports: [BreadcrumbsComponent, ImageBanner2Component, PersonGridListComponent, TranslateModule],
  templateUrl: './competition-steering-committee.component.html',
  styleUrl: './competition-steering-committee.component.scss',
  animations: [fadeInOut],
})
export class CompetitionSteeringCommitteeComponent implements OnInit {
  competitionSteeringCommitteeContentType: string = 'SbuxCompetitionSteeringCommittee';
  competitionSteeringCommitteeData: PersonListModel[] = [];
  parentIdentifier!: string;

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/competitions',
      label: 'Competitions'
    }
  ]

  // Ignore for now.
  imageBanner2DataUrl = '/assets/data/image-banner-2.json';

  constructor(
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title
  ) {
    this.translation.get(['GENERAL.MENU.COMPETITIONS', 'COMPETITIONS.COMMITTEE.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.COMPETITIONS'];
        titleService.setTitle(res['COMPETITIONS.COMMITTEE.HEADER']);
      }
    })
  }

  ngOnInit(): void {
    this.contentService.postContent(this.competitionSteeringCommitteeContentType).subscribe({
      next: res => {
        // Committee
        res.forEach((item: any) => {
          const committeeObj: PersonListModel = {
            content_id: item.identifier,
            content_type: item.contentType,
            image_src: item.profileImage,
            name: item.title,
            title: item.personTitle ?? '',
            market: item.market.toUpperCase() !== 'NOT APPLICABLE' ? item.market : '',
            bio_type: item.bioType
          };

          // Text Bio
          if (item.bioType.toUpperCase() === 'TEXT') {
            committeeObj.bio_text = item.bioText;
          }

          // Video Bio
          if (item.bioType.toUpperCase() === 'VIDEO') {
            committeeObj.bio_video_link = item.bioVideo;
          }

          this.competitionSteeringCommitteeData.push(committeeObj);
        })
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
