<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <div class="container">
    <h1 class="mb-3">Search Results</h1>
    <div *ngIf="noResults" class="no-results">
      No results found, please change your search terms and try again.
    </div>

    <div *ngIf="hasResults">
      <div class="row">
        <div class="col-12 col-lg-7">
          Showing 6 results for: <strong>{{searchTerm}}</strong>
        </div>

        <div class="col-12 col-lg-5 mt-3 mt-lg-0">
          <div class="form-group search-sorting">
            <label class="form-label" for="search-results-sort-by">Sort by</label>
            <select class="form-select" id="search-results-sort-by">
              <option>Relevance</option>
              <option>Name</option>
            </select>
          </div>
        </div>

        <div class="results-wrapper col-12 mt-4">
          <section class="mb-4">
            <h3 class="mb-4">Section name</h3>
            <!-- <app-grid-list-3 [dataUrl]="searchResultDataUrl"></app-grid-list-3> -->
          </section>

          <section class="mb-4">
            <h3 class="mb-4">Section name</h3>
            <!-- <app-grid-list-3 [dataUrl]="searchResultDataUrl"></app-grid-list-3> -->
          </section>

          <section class="mb-4">
            <h3 class="mb-4">Section name</h3>
            <!-- <app-grid-list-3 [dataUrl]="searchResultDataUrl"></app-grid-list-3> -->
          </section>

          <div class="d-flex align-items-center justify-content-center">
            <button type="button" class="btn btn-white btn-sm">Load more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>