import { Component } from '@angular/core';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { PersonGridListComponent } from '../../components/person-grid-list/person-grid-list.component';
import { CommonModule } from '@angular/common';
import { PersonListModel } from '../../models';
import { ContentService } from '../../services/content.service';
import { fadeInOut } from '../../animation';

@Component({
  selector: 'app-preview-person',
  standalone: true,
  imports: [BreadcrumbsComponent, PersonGridListComponent, CommonModule],
  templateUrl: './preview-person.component.html',
  styleUrl: './preview-person.component.scss',
  animations: [fadeInOut]
})
export class PreviewPersonComponent {
  personContentType: string = 'SbuxPerson';
  personData: PersonListModel[] = [];

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/home',
      label: 'Home'
    }
  ]

  constructor(
    private contentService: ContentService,
  ) { }

  ngOnInit(): void {
    this.contentService.postContent(this.personContentType, undefined, 10000).subscribe({
      next: res => {
        // person
        res.forEach((item: any) => {
          const committeeObj: PersonListModel = {
            content_id: item.identifier,
            content_type: item.contentType,
            image_src: item.profileImage,
            name: item.title,
            title: item.personTitle ?? '',
            market: item.market.toUpperCase() !== 'NOT APPLICABLE' ? item.market : '',
            bio_type: item.bioType
          };

          // Text Bio
          if (item.bioType.toUpperCase() === 'TEXT') {
            committeeObj.bio_text = item.bioText;
          }

          // Video Bio
          if (item.bioType.toUpperCase() === 'VIDEO') {
            committeeObj.bio_video_link = item.bioVideo;
          }

          this.personData.push(committeeObj);
        })
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
