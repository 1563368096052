import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { HtmlContentsComponent } from "../../components/html-contents/html-contents.component";
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ContentService } from '../../services/content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ImageBanner1Model } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bap-article',
  standalone: true,
  templateUrl: './bap-article.component.html',
  styleUrl: './bap-article.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, HtmlContentsComponent, BreadcrumbsComponent, ImageBanner1Component]
})
export class BapArticleComponent implements OnInit, OnDestroy {
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/bap',
      label: 'Black Apron Program'
    }
  ]

  parentIdentifier!: string;
  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicItemContentType: string = 'SbuxBapTopicItem';
  bapTopicItemArticleIdentifier!: string;
  bapTopicItemArticleContent: string = '';

  imageBannerData!: ImageBanner1Model;

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private translation: TranslateService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private toastr: ToastrService,
    private title: Title
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Translation
    this.translation.get(['BAP.HEADER']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['BAP.HEADER'];
      }
    })

    this.route.params.subscribe(params => {
      this.parentIdentifier = params['pId'];
      this.bapTopicItemArticleIdentifier = params['contentId'];

      // Set Breadcrumb
      const segmentUrl = this.router.url.split('/');
      segmentUrl.pop();
      segmentUrl.pop();
      this.breadcrumbsData[0].link = segmentUrl.join('/');

      // Workshop Details Data
      this.contentService.postContent(this.bapTopicItemContentType, this.bapTopicItemArticleIdentifier).subscribe({
        next: res => {
          if (res.length > 0) {
            const data = res[0];

            const canViewContent = this.contentService.canViewContent(data.blackApronExclusive.replace(/ /g, '_').toUpperCase());
            if (!canViewContent && !this.configService.isPreview) {
              this.router.navigate(['/bap-actual']).then(() => {
                this.toastr.error('Partner does not have tier required to access the content.');
              });
            }

            // Set Data
            this.title.setTitle(data.title);
            this.bapTopicItemArticleContent = data.bodyContents;
            this.imageBannerData = {
              content_id: `${data.title}-banner-id`,
              image_src: {
                lg: data.bannerImage,
                sm: data.bannerImageMobile
              }
            }

          }
        },
        error: err => {
          console.log(err);
        },
        complete: () => {
          if (this.parentIdentifier) {
            this.contentService.postContent(this.bapTopicContentType, this.parentIdentifier).subscribe({
              next: res => {
                // Breadcrumb label
                this.breadcrumbsData[0].label = res[0].title;
              },
              error: err => {
                console.log(err);
              }
            });
          }

          // Add Content Analytic
          if (!this.configService.isPreview) {
            this.analyticsService.createContentAnalytic(this.bapTopicItemArticleIdentifier, this.bapTopicItemContentType, this.router.url);
          }
        }
      });
    })
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }

  sanitizeStyle(value: string) {
    return this.sanitizer.bypassSecurityTrustHtml(value)
  }
}
