import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { CommonModule } from '@angular/common';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { CompetitionInformationModel } from '../../models';
import { MarkdownPipe } from '../../markdown.pipe';
import { FolderIcon } from "../../icons";
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';


@Component({
  selector: 'app-competition-information',
  standalone: true,
  templateUrl: './competition-information.component.html',
  styleUrl: './competition-information.component.scss',
  animations: [fadeInOut],
  imports: [BreadcrumbsComponent, CommonModule, MarkdownPipe, FolderIcon, RouterLink]
})
export class CompetitionInformationComponent implements OnInit {
  competitionInformatinoIdentifier!: string;
  competitionInformationContentType: string = 'SBUXCompetitionInformation';
  compeititonInformationData: CompetitionInformationModel = {
    content_id: '',
    heading: {
      logo_src: '',
      headline: '',
      description: ''
    },
    judging_criteria: [],
    competition_objectives: '',
    competition_time: '',
    competition_requirements: '',
    competition_parts: []
  };

  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/competitions',
      label: 'Competitions'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) {
    this.translation.get(['GENERAL.MENU.COMPETITIONS']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.COMPETITIONS'];
      }
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.competitionInformatinoIdentifier = params['compInfoId'];

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.competitionInformatinoIdentifier, this.competitionInformationContentType, this.router.url);
      }
    })

    this.contentService.postContent(this.competitionInformationContentType, this.competitionInformatinoIdentifier).subscribe({
      next: res => {
        const data = res[0];

        // Competition Information
        this.compeititonInformationData.content_id = data.identifier;
        this.compeititonInformationData.competition_objectives = data.objective;
        this.compeititonInformationData.competition_requirements = data.competitionRequirement;
        this.compeititonInformationData.competition_time = data.competitionTime;
        this.compeititonInformationData.heading.headline = data.title;
        this.compeititonInformationData.heading.description = data.descriptions;
        this.compeititonInformationData.heading.logo_src = data.icon;

        // Scoresheet Judge Document
        if (data.scoresheetJudgeCriteriaDocument) {
          const scoresheetJudgeDocumentJSON = JSON.parse(data.scoresheetJudgeCriteriaDocument);
          scoresheetJudgeDocumentJSON.forEach((item: any) => {
            this.compeititonInformationData.judging_criteria?.push({
              file_src: item.src,
              display_name: item.name
            })
          });
        }

        // Compatition Part
        data.competitionPart.forEach((item: any) => {
          this.compeititonInformationData.competition_parts.push({
            name: item.title,
            objective: item.objective,
            scoring_criteria: item.scoringCriteria
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
