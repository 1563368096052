import { Component, Input, OnInit } from '@angular/core';
import { XmarkIcon } from "../../icons/icon-xmark";
import { HtmlContentsComponent } from "../html-contents/html-contents.component";
import { ContentService } from '../../services/content.service';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config.service';
import { AnalyticsService } from '../../services/analytics.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bap-flash-card-modal',
  standalone: true,
  templateUrl: './bap-flash-card-modal.component.html',
  styleUrl: './bap-flash-card-modal.component.scss',
  imports: [XmarkIcon, HtmlContentsComponent, TranslateModule, CommonModule]
})
export class BapFlashCardModalComponent implements OnInit {
  @Input() modal: any;
  @Input() id!: string;

  bapTopicItemContentType: string = 'SbuxBapTopicItem';
  bapTopicItemData: {
    title: string;
    image_src: {
      sm: string;
      lg: string;
    };
    content: string
  } = {
      title: '',
      image_src: {
        sm: '',
        lg: ''
      },
      content: ''
    }

  constructor(
    private contentService: ContentService,
    private configService: ConfigService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // Set page data
    this.contentService.postContent(this.bapTopicItemContentType, this.id).subscribe({
      next: res => {
        if (res.length > 0) {
          this.bapTopicItemData.title = res[0].title;
          this.bapTopicItemData.image_src.sm = res[0].bannerImageMobile;
          this.bapTopicItemData.image_src.lg = res[0].bannerImage;
          this.bapTopicItemData.content = res[0].bodyContents;
        }
      },
      complete: () => {
        // Add Content Analytic
        if (!this.configService.isPreview) {
          this.analyticsService.createContentAnalytic(this.id, this.bapTopicItemContentType, this.router.url);
        }
      }
    })
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
