import { Component, Renderer2, ElementRef, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { CommonModule } from '@angular/common';
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { GridList1Component } from "../../components/grid-list-1/grid-list-1.component";
import { GridList2Component } from "../../components/grid-list-2/grid-list-2.component";
import { BannerCarousel1Component } from '../../components/banner-carousel-1/banner-carousel-1.component';
import { BannerCarousel2Component } from "../../components/banner-carousel-2/banner-carousel-2.component";
import { ListCarousel1Component } from "../../components/list-carousel-1/list-carousel-1.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { BannerCarousel1Model, BannerCarousel2Model, GridList2Model, ListCarousel1Model, NotificationsCarouselModel } from '../../models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsModalComponent } from '../../components/notifications-modal/notifications-modal.component';
import { Title } from '@angular/platform-browser';
import { ConfigService } from '../../services/config.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  animations: [fadeInOut],
  imports: [BannerCarousel1Component, GridList3Component, GridList1Component, GridList2Component, BannerCarousel2Component, ListCarousel1Component, CommonModule, TranslateModule]
})

export class HomeComponent implements OnInit {
  noticeData: string[] = [];
  bannerData: BannerCarousel1Model[] = [];
  recommendedData: GridList2Model[] = [];
  specialTopicData: BannerCarousel2Model[] = [];
  notificationData: NotificationsCarouselModel[] = [];
  timeShortform: { hour: string, minute: string } = {
    hour: 'h',
    minute: 'm'
  }
  exploreData: ListCarousel1Model[] = [];
  exploreDataTopLabelTranslate: { event: string, event_video: string, learning_topic: string, learning_lesson: string, workshop: string, competitions: string } = {
    event: 'Event',
    event_video: 'Event Video',
    learning_topic: 'Learning Topic',
    learning_lesson: 'Learning Lesson',
    workshop: 'Workshop',
    competitions: 'Competitions'
  }
  exploreDataUnitTranslate: { lesson: string, video: string, workshop: string } = {
    lesson: 'lesson',
    video: 'video',
    workshop: 'workshop'
  }

  homeNoticeContentType = 'SbuxHomeNotice';
  homeBannerContentType = 'SbuxHomeBanner';
  homeRecommendedContentType = 'SbuxHomeRecommendedList';
  homeSpecialTopicContentType = 'SbuxHomeSpecialTopic';
  homeExploreContentType = 'SbuxHomeExploreList';
  homeNotificationContentType = 'SbuxHomeNotification';
  homeMainCategoryData = [
    {
      "content_id": "learn-content-id",
      "image_src": "/assets/images/learning-centre/Learning Center-1200x675.webp",
      "link": "/learn",
      "headline": "Learning Centre"
    },
    {
      "content_id": "competitions-content-id",
      "image_src": "/assets/images/competitions/Competition-1200x675.webp",
      "link": "/competitions",
      "headline": "Competitions"
    },
    {
      "content_id": "events-content-id",
      "image_src": "/assets/images/events/Event-1200x675.webp",
      "link": "/events",
      "headline": "Events & Activities"
    },
    {
      "content_id": "bap-content-id",
      "image_src": "/assets/images/bap/BAP-1200x675.webp",
      "link": "/bap",
      "headline": "Black Apron Program"
    }
  ];

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private contentService: ContentService,
    private modalService: NgbModal,
    private translation: TranslateService,
    private titleService: Title,
    private configService: ConfigService,
    private languageService: LanguageService
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'flex-1');
    this.renderer.addClass(this.el.nativeElement, 'page-home');

    // Get Notification Local Storage
    const invalidatedNotification = localStorage.getItem('notifications')?.split(','); // list if strings separated by comma

    // Get breadcrumbs translation
    this.translation.get([
      'GENERAL.MENU.HOME',
      'GENERAL.MENU.LEARNING_CENTRE',
      'GENERAL.MENU.COMPETITIONS',
      'GENERAL.MENU.EVENTS',
      'GENERAL.MENU.WORKSHOPS',
      'GENERAL.MENU.BAP',
      'GENERAL.TIME.HOUR_SHORTFORM',
      'GENERAL.TIME.MINUTE_SHORTFORM',
      'HOME.EXPLORE.EVENT',
      'HOME.EXPLORE.EVENT_VIDEO',
      'HOME.EXPLORE.LEARNING_TOPIC',
      'HOME.EXPLORE.LEARNING_LESSON',
      'HOME.EXPLORE.WORKSHOP',
      'HOME.EXPLORE.COMPETITIONS',
      'HOME.EXPLORE.COUNT_LESSON',
      'HOME.EXPLORE.COUNT_VIDEO',
      'HOME.EXPLORE.COUNT_WORKSHOP'
    ]).subscribe({
      next: res => {

        this.titleService.setTitle(res['GENERAL.MENU.HOME']);

        this.homeMainCategoryData[0].headline = res['GENERAL.MENU.LEARNING_CENTRE'];
        this.homeMainCategoryData[1].headline = res['GENERAL.MENU.COMPETITIONS'];
        this.homeMainCategoryData[2].headline = res['GENERAL.MENU.EVENTS'];
        this.homeMainCategoryData[3].headline = res['GENERAL.MENU.BAP'];

        this.timeShortform.hour = res['GENERAL.TIME.HOUR_SHORTFORM'];
        this.timeShortform.minute = res['GENERAL.TIME.MINUTE_SHORTFORM'];

        this.exploreDataTopLabelTranslate.event = res['HOME.EXPLORE.EVENT'];
        this.exploreDataTopLabelTranslate.event_video = res['HOME.EXPLORE.EVENT_VIDEO'];
        this.exploreDataTopLabelTranslate.learning_topic = res['HOME.EXPLORE.LEARNING_TOPIC'];
        this.exploreDataTopLabelTranslate.learning_lesson = res['HOME.EXPLORE.LEARNING_LESSON'];
        this.exploreDataTopLabelTranslate.workshop = res['HOME.EXPLORE.WORKSHOP'];
        this.exploreDataTopLabelTranslate.competitions = res['HOME.EXPLORE.COMPETITIONS'];

        this.exploreDataUnitTranslate.lesson = res['HOME.EXPLORE.COUNT_LESSON'];
        this.exploreDataUnitTranslate.video = res['HOME.EXPLORE.COUNT_VIDEO'];
        this.exploreDataUnitTranslate.workshop = res['HOME.EXPLORE.COUNT_WORKSHOP'];
      },
      complete: () => {

        //  Notification POST Request
        this.contentService.postContent(this.homeNotificationContentType).subscribe({
          next: res => {
            res.forEach((item: any) => {
              // IF List does not exist in localstorage OR List does not contain id
              if ((!invalidatedNotification || !invalidatedNotification.includes(item.identifier)) || this.configService.isPreview) {
                this.notificationData.push({
                  content_id: item.identifier,
                  category: item.category,
                  image_src: {
                    sm: item.imageMobile ?? '',
                    lg: item.image
                  },
                  headline: item.title,
                  subheadline: item.subtitle,
                  body: item.bodyContents,
                  button: {
                    label: item.ctaLabel,
                    link: item.ctaLink
                  }
                })
              }
            });
          },
          error: err => {
            console.log(err);
          },
          complete: () => {
            if (this.notificationData.length > 0) {
              this.openNotificationsModal();
            }
          }
        });

        // Notice POST Request
        this.contentService.postContent(this.homeNoticeContentType).subscribe({
          next: res => {
            res.forEach((item: any) => {
              this.noticeData.push(item.title);
            });
          },
          error: err => {
            console.log(err);
          }
        });

        // Banner POST Request
        this.contentService.postContent(this.homeBannerContentType).subscribe({
          next: res => {
            res.forEach((item: any) => {
              this.bannerData.push({
                content_id: item.identifier,
                type: item.type,
                image_src: {
                  sm: item.bannerImageMobile,
                  lg: item.bannerImage
                },
                headline: item.title,
                black_apron_exclusive: item.blackApronExclusive,
                descriptions: item.description,
                top_label: item.topSubtitle,
                start_date: item.startDateTime,
                end_date: item.endDateTime,
                button: {
                  label: item.ctaLabel,
                  link: item.ctaLink
                }
              })
            });
          },
          error: err => {
            console.log(err);
          }
        });

        // Recommended POST Request
        this.contentService.postContent(this.homeRecommendedContentType, '', this.configService.isPreview ? undefined : 2).subscribe({
          next: res => {
            res.forEach((item: any) => {
              this.recommendedData.push({
                content_id: item.identifier,
                headline: item.title,
                top_label: item.topSubtitle,
                image_src: item.coverImage,
                link: item.link
              })
            });
          },
          error: err => {
            console.log(err);
          }
        });

        // Special Topic POST Request
        this.contentService.postContent(this.homeSpecialTopicContentType).subscribe({
          next: res => {
            res.forEach((item: any) => {
              this.specialTopicData.push({
                content_id: item.identifier,
                image_src: {
                  sm: item.bannerImageMobile,
                  lg: item.bannerImage
                },
                link: item.link,
                headline: item.title,
                black_apron_exclusive: item.blackApronExclusive,
                subheadline: item.bottomSubtitle
              })
            });
          },
          error: err => {
            console.log(err);
          }
        });

        // Explore List POST Request
        this.contentService.postContent(this.homeExploreContentType).subscribe({
          next: res => {
            res.forEach((item: any) => {
              const linkContentIdArr = item.linkContentId.split(',');
              const linkTypeSnakeCaseKey: any = item.linkContentType.toLowerCase().replace(' ', '_');

              // Promise
              this.getContentCuratedData(item.linkContentType, linkContentIdArr)
                .then(curatedData => {
                  this.exploreData.push({
                    content_id: item.identifier,
                    image_src: item.coverImage,
                    headline: item.title,
                    top_label: this.exploreDataTopLabelTranslate[linkTypeSnakeCaseKey as keyof object],
                    descriptions: item.description,
                    link: curatedData.link,
                    black_apron_exclusive: item.blackApronExclusive,
                    curated_data: {
                      label: curatedData.label,
                      duration: this.contentService.formatSecToHM(curatedData.durationCount, this.timeShortform)
                    }
                  })
                })
                .catch(error => {
                  console.error(error);
                })
            });
          },
          error: err => {
            console.log(err);
          }
        });
      },
    })
  }

  getContentCuratedData(type: string, contentIdArr: string): Promise<any> {
    let switchContentType: string = '';
    let countContentField: string = '';
    let switchContentCountUnit: string = '';
    let contentResponse: any = [];
    let curatedData: { label: string, link: string, durationCount: number } = { label: '', link: '', durationCount: 0 };
    const linkContentLastId = contentIdArr[contentIdArr.length - 1];

    // CMS content type switch
    switch (type.toUpperCase()) {
      case 'EVENT':
        switchContentType = 'SbuxEvent';
        countContentField = 'videoHighlight';
        switchContentCountUnit = this.exploreDataUnitTranslate.video;
        curatedData.link = `/events/${contentIdArr[0]}`;
        break;
      case 'EVENT VIDEO':
        switchContentType = 'SbuxEventVideo';
        countContentField = '';
        switchContentCountUnit = this.exploreDataUnitTranslate.video;
        curatedData.link = `/events/videos/${contentIdArr[0]}`;
        break;
      case 'LEARNING TOPIC':
        switchContentType = 'SbuxLearningTopic';
        countContentField = 'lessonList';
        switchContentCountUnit = this.exploreDataUnitTranslate.lesson;
        curatedData.link = `/learn/${contentIdArr[0]}`;
        break;
      case 'LEARNING LESSON':
        switchContentType = 'SbuxLearningLesson';
        countContentField = '';
        switchContentCountUnit = this.exploreDataUnitTranslate.lesson;
        curatedData.link = `/learn`;
        break;
      case 'WORKSHOP':
        switchContentType = 'SbuxWorkshopArchive';
        countContentField = '';
        switchContentCountUnit = this.exploreDataUnitTranslate.workshop;
        curatedData.link = `/workshops/videos/${contentIdArr[0]}`;
        break;
      case 'COMPETITIONS':
        switchContentType = 'SbuxCompetition';
        countContentField = 'competitionVideos';
        switchContentCountUnit = this.exploreDataUnitTranslate.video;
        curatedData.link = `/competitions/${contentIdArr[0]}`;
        break;
      default:
        break;
    }

    // POST request to get switch content data
    return new Promise((resolve, reject) => {
      this.contentService.postContent(switchContentType, linkContentLastId, undefined, undefined, 'null').subscribe({
        next: res => {
          contentResponse = res[0];

          // No data
          if (res.length === 0) {
            return;
          }

          // For Learning Lesson type
          if (type.toUpperCase() === 'LEARNING LESSON') {
            // Determine is video or article
            curatedData.link += `/${contentResponse.type.toUpperCase() === 'VIDEO' ? 'videos' : 'articles'}/${contentIdArr[0]}`;
          }

          if (countContentField !== '') {
            // Calculate total duration
            let durationCount: number = 0;

            contentResponse[countContentField].forEach((item: any) => {
              let duration = item.videoDuration;

              if (countContentField.toUpperCase() === 'LESSONLIST' && item.type.toUpperCase() === 'ARTICLE') {
                duration = item.contentDuration;
              }
              durationCount += Number.parseInt(duration);
            });

            // Calculate total number of child
            const count = contentResponse[countContentField].length;

            curatedData.label = `${count} ${switchContentCountUnit}${this.languageService.getLanguage() === '1' && count > 1 ? 's' : ''}`;
            curatedData.durationCount = durationCount;
          } else {
            let duration = contentResponse.videoDuration;

            // Learning Lesson Type = Article
            if (switchContentType.toUpperCase() === 'SBUXLEARNINGLESSON' && contentResponse.type.toUpperCase() === 'ARTICLE') {
              duration = contentResponse.contentDuration;
            }

            curatedData.label = `1 ${switchContentCountUnit}`;
            curatedData.durationCount = Number.parseInt(duration);
          }
          resolve(curatedData);
        },
        error: err => {
          reject(err);
        }
      });
    })
  }

  openNotificationsModal() {
    const modalRef = this.modalService.open(NotificationsModalComponent, {
      centered: true,
      windowClass: 'notifications-modal',
      size: 'xl',
      scrollable: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.data = this.notificationData;
  }
}

