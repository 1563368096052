<div class="component-grid-list-3">
  <div class="container">
    <div class="row">
      <div *ngFor="let item of data" class="col-12 col-md-6 col-lg-4">
        <div class="list-item" [ngClass]="{
          'item-live': item.type === 'LIVE',
          'item-upcoming': item.type === 'UPCOMING',
          'item-regular': item.type === 'REGULAR' || item.type === 'FLASHCARD'
        }">
          <figure [title]="item.headline">
            <a *ngIf="item.link"
              (click)="item.disabled ? onDisabledContent($event) : item.type?.toUpperCase() === 'FLASHCARD' ? onFlashCard($event,item.identifier ?? '',item.level_requirement ?? '') : null "
              [href]="item.type?.toUpperCase() === 'FLASHCARD' ? '#' : currentUrl+item.link">
              <img [src]="item.image_src" alt="{{item.headline}}-img">
              <div *ngIf="item.is_video" class="label label-play-icon">
                <span class="icon-img">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                    <path
                      d="M320-273v-414q0-17 12-28.5t28-11.5q5 0 10.5 1.5T381-721l326 207q9 6 13.5 15t4.5 19q0 10-4.5 19T707-446L381-239q-5 3-10.5 4.5T360-233q-16 0-28-11.5T320-273Z" />
                  </svg>
                </span>
              </div>
            </a>
            <img *ngIf="!item.link" [src]="item.image_src" alt="{{item.headline}}-img">

            <div *ngIf="item.mark_as_new" class="label label-new">{{"GENERAL.CONTENT.NEW" | translate}}</div>
            <div *ngIf="item.mark_as_completed" class="label label-completed">
              {{"GENERAL.CONTENT.COMPLETED" | translate}}
            </div>
            <div *ngIf="item.level_requirement && item.disabled"
              class="tier-overlay d-flex flex-wrap flex-column align-items-center justify-content-center p-3 text-center">
              <ng-container *ngIf="item.level_requirement === 'GREEN_APRON'">
                <div class="fs-5 mb-3 px-3">
                  {{"BAP.BAP_REQUIREMENT.GREEN_APRON" | translate}}
                </div>
                <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                  (click)="onUnlockBAPContentClick()">
                  {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
                </button>
              </ng-container>
              <ng-container *ngIf="item.level_requirement === 'SCA_CERTIFIED_COFFEE_MASTER'">
                <div class="fs-5 mb-3 px-3">
                  {{"BAP.BAP_REQUIREMENT.SCA" | translate}}
                </div>
                <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                  (click)="onUnlockBAPContentClick()">
                  {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
                </button>
              </ng-container>
              <ng-container *ngIf="item.level_requirement === 'STAR_1'">
                <div class="fs-5 mb-3 px-3">
                  {{"BAP.BAP_REQUIREMENT.STAR_1" | translate}}
                </div>
                <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                  (click)="onUnlockBAPContentClick()">
                  {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
                </button>
              </ng-container>
              <ng-container *ngIf="item.level_requirement === 'STAR_2'">
                <div class="fs-5 mb-3 px-3">
                  {{"BAP.BAP_REQUIREMENT.STAR_2" | translate}}
                </div>
                <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                  (click)="onUnlockBAPContentClick()">
                  {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
                </button>
              </ng-container>
              <ng-container *ngIf="item.level_requirement === 'STAR_3'">
                <div class="fs-5 mb-3 px-3">
                  {{"BAP.BAP_REQUIREMENT.STAR_3" | translate}}
                </div>
                <button type="button" name="btn-unlock-content" class="btn btn-primary btn-sm"
                  (click)="onUnlockBAPContentClick()">
                  {{"BAP.UNLOCK_BUTTON_LABEL" | translate}}
                </button>
              </ng-container>
            </div>
          </figure>
          <div class="info">
            <div *ngIf="item.top_label" class="top-subtitle fs-smaller fw-bold text-uppercase mb-3">{{item.top_label}}
            </div>
            <h3 class="headline fw-normal text-dark pe-3" [innerHTML]="item.headline"></h3>

            <h5 *ngIf="item.subheadline" class="subheadline text-dark pe-3">{{item.subheadline}}</h5>

            <div *ngIf="item.descriptions" class="descriptions fs-small pe-3 " [innerHTML]="item.descriptions"></div>
            <div *ngIf="item.curated_data" class="curated-data">
              <div class="data-label label label-curated-data">{{item.curated_data.label}}</div>
              <div *ngIf="item.curated_data.duration" class="time label label-curated-data">
                {{item.curated_data.duration}}
              </div>
            </div>
            <div class="call-to-action"
              *ngIf="(item.type === 'REGULAR' && item.button) || item.type === 'LIVE' || item.type === 'UPCOMING'">
              <a *ngIf="item.type === 'LIVE'" [routerLink]="[item.button?.link]"
                class="btn btn-primary-live w-100 w-md-auto btn-sm">
                {{"GENERAL.BUTTON.JOIN_NOW" | translate}}
              </a>

              <a *ngIf="item.type === 'REGULAR'" [routerLink]="[item.button?.link]"
                class="btn btn-alert w-100 w-md-auto btn-sm">{{item.button?.label}}</a>

              <span *ngIf="item.type === 'UPCOMING'" ngbDropdown #addToCalendarDropDown="ngbDropdown"
                placement="bottom-start" class="custom-dropdown add-to-calendar-dropdown">
                <button type="button" class="btn btn-primary btn-add-to-calendar w-100 w-md-auto btn-sm"
                  id="addToCalendarDropDown" ngbDropdownToggle>{{"GENERAL.BUTTON.ADD_TO_CALENDAR" | translate}}</button>

                <!-- WL prepared this just in case -->
                <!-- <div ngbDropdownMenu aria-labelledby="addToCalendarDropDown">
                  <a ngbDropdownItem [routerLink]="[item.button?.apple_calendar_link]">
                    <span class="icon-img">
                      <icon-apple></icon-apple>
                    </span>
                    Apple Calendar
                  </a>
                  <a ngbDropdownItem [routerLink]="[item.button?.google_calendar_link]">
                    <span class="icon-img">
                      <icon-google></icon-google>
                    </span>
                    Google Calendar</a>
                  <a ngbDropdownItem [routerLink]="[item.button?.ical_calendar_link]">
                    <span class="icon-img">
                      <icon-calendar></icon-calendar>
                    </span>
                    iCal</a>
                  <a ngbDropdownItem [routerLink]="[item.button?.microsoft_calendar_link]">
                    <span class="icon-img">
                      <icon-microsoft-365></icon-microsoft-365>
                    </span>
                    Microsoft Outlook Calendar</a>
                  <a ngbDropdownItem [routerLink]="[item.button?.yahoo_calendar_link]">
                    <span class="icon-img">
                      <icon-yahoo></icon-yahoo>
                    </span>
                    Yahoo Calendar</a>
                </div> -->
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>