import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { GridList3Model } from '../../models';
import { ContentTypeSegment, ContentTypeGroupLink, ContentTypeSegmentName, ContentTypeName } from '../../enum/contentType.enum';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';
import { BapFlashCardModalComponent } from '../../components/bap-flash-card-modal/bap-flash-card-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsModalComponent } from '../../components/notifications-modal/notifications-modal.component';
import { SpinnerIcon } from '../../icons';
import { UnlockBapContentModalComponent } from '../../components/unlock-bap-content-modal/unlock-bap-content-modal.component';

interface groupedSearchResults {
  name: string;
  contentType: string;
  data: GridList3Model[];
}

@Component({
  selector: 'app-search-results',
  standalone: true,
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, GridList3Component, BreadcrumbsComponent, TranslateModule, SpinnerIcon]
})

export class SearchResultsComponent implements OnInit {
  isLoading: boolean = true;
  isLoadingMore: boolean = false;
  limit: number = 10;
  offset: number = 0;
  noMoreResults: boolean = false;
  searchTerm: string = '';
  searchResultsBackup: any[] = [];
  searchResultsData: GridList3Model[] = [];
  totalSearchResults: number = 0;

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Search Results"
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private contentService: ContentService,
    private toastr: ToastrService,
    private configService: ConfigService,
    private modalService: NgbModal
  ) {
    this.route.queryParams.subscribe(params => {
      this.searchTerm = params['s'];
    });
  }

  ngOnInit(): void {
    if (!this.searchTerm) {
      window.location.href = '/home';
      return;
    }
    this.getSearchResults();
  }

  getSearchResults() {
    this.contentService.searchContent(this.searchTerm, this.offset, this.limit).subscribe({
      next: res => {

        // No result
        if (res.contents.length === 0) {
          this.noMoreResults = true;
          return;
        }

        // Result returned less than limit (No more results) 
        if (res.contents.length < this.limit || res.page.element_count < this.limit || (this.limit + this.offset) >= res.page.total_elements) {
          this.noMoreResults = true;
        }

        this.totalSearchResults = res.page.total_elements;
        this.searchResultsBackup.push(...res.contents);

        res.contents.forEach(async (results: any) => {
          const segmentOfResult = ContentTypeSegment.find((segment: { segment: string, contentType: string[] }) => segment.contentType.includes(results.contentType.toUpperCase()));

          let dataObj: GridList3Model = {
            content_id: results.identifier,
            image_src: results.coverImage || results.image || results.bannerImage || results.icon,
            headline: results.title,
            subheadline: results.subtitle,
            link: await this.getContentTypeGroupLink(results),
            descriptions: this.getContentTypeDescriptions(results),
            level_requirement: results.contentType === 'SbuxBapTopicItem' ? results.blackApronExclusive.replace(/ /g, '_').toUpperCase() : undefined,
            disabled: results.contentType === 'SbuxBapTopicItem' ? !this.contentService.canViewContent(results.blackApronExclusive.replace(/ /g, '_').toUpperCase()) : undefined,
            video_duration: this.contentService.formatSecToHMS(results.videoDuration) || '',
            tags: []
          }

          if (segmentOfResult) {
            const tagArr = [
              ContentTypeSegmentName[segmentOfResult.segment as keyof typeof ContentTypeSegmentName] as string, // Category
              ContentTypeName[results.contentType.toUpperCase() as keyof typeof ContentTypeName] as string // Sub-category
            ];
            dataObj.tags = [... new Set(tagArr)]; // filter duplicates
          }

          if ((results.contentType === 'SbuxBapTopicItem' && results.type.toUpperCase() === 'FLASH CARD') || results.contentType === 'SbuxHomeNotification') {
            dataObj.type = 'FLASHCARD';
          }
          this.searchResultsData.push(dataObj);
        })
      },
      complete: () => {
        this.offset += this.limit;
        this.isLoading = false;
        this.isLoadingMore = false;
      }
    });
  }

  async getContentTypeGroupLink(result: any): Promise<string> {
    switch (result.contentType) {
      case 'SbuxHomeExploreList':

        // default
        if (result.linkContentType !== 'Learning Lesson') {
          return this.getHomeExploreListContentTypeLink(result.linkContentType, result.linkContentId);
        }

        // Learning Lesson (video or article)
        const res = await lastValueFrom(this.contentService.postContent('SbuxLearningLesson', result.linkContentId));
        if (res.length > 0 && res[0].type) {
          return `/learn/${res[0].type.toUpperCase() === 'VIDEO' ? 'videos' : 'articles'}/${res[0].identifier}`
        } else {
          return '/home';
        }

      case 'SbuxHomeRecommendedList':
      case 'SbuxHomeSpecialTopic':
      case 'SbuxEventHighlight':
      case 'SbuxFeaturedEvent':
      case 'SbuxEventArchive':
        return result.link;

      case 'SbuxHomeNotification':
        return '#';

      case 'SbuxLearningLesson':
        if (result.type.toUpperCase() === 'ARTICLE') {
          return `${ContentTypeGroupLink.SBUXLEARNINGLESSON}/articles/${result.identifier}`;
        } else if (result.type.toUpperCase() === 'VIDEO') {
          return `${ContentTypeGroupLink.SBUXLEARNINGLESSON}/videos/${result.identifier}`;
        }
        return '';

      case 'SbuxCompetitionInformation':
        return `${ContentTypeGroupLink.SBUXCOMPETITIONINFORMATION}/informations/${result.title.toLowerCase().replaceAll(/ /g, '-')}`;

      case 'SbuxBapTopicItem':
        if (result.type.toUpperCase() === 'WORKSHOP') {
          return `bap-actual/bap-workshop-details/${result.identifier}`;
        } else if (result.type.toUpperCase() === 'ARTICLE') {
          return `bap-actual/bap-articles/${result.identifier}`;
        } else if (result.type.toUpperCase() === 'FLASH CARD') {
          return `#`
        }
        return 'helo';

      default:
        return `${ContentTypeGroupLink[result.contentType.toUpperCase() as keyof typeof ContentTypeGroupLink]}/${result.identifier}`;
    }
  }

  getContentTypeDescriptions(results: any): string {
    switch (results.contentType) {
      case 'SbuxHomeNotification':
        return results.bodyContents;
      default:
        return results.description || results.descriptions;
    }
  }

  getHomeExploreListContentTypeLink(type: string, contentId: string): string {
    // CMS content type switch
    switch (type.toUpperCase()) {
      case 'EVENT':
        return `/events/${contentId}`;
      case 'EVENT VIDEO':
        return `/events/videos/${contentId}`;
      case 'LEARNING TOPIC':
        return `/learn/${contentId}`;
      case 'LEARNING LESSON':
        return "/home";
      case 'WORKSHOP':
        return `/workshops/videos/${contentId}`;
      case 'COMPETITIONS':
        return `/competitions/${contentId}`;
      default:
        return "/home";
    }
  }

  openFlashCardModal(data: { identifier: string, tier: string }) {
    const flashCardContent: any = this.searchResultsBackup.find((content: any) => content.identifier === data.identifier);

    if (!flashCardContent) {
      this.toastr.error('Content not found');
      return;
    }

    switch (flashCardContent.contentType) {
      case 'SbuxBapTopicItem':
        // Check tier access
        const canViewContent = this.contentService.canViewContent(data.tier);

        if (!canViewContent && !this.configService.isPreview) {
          this.toastr.error('Partner does not have tier required to access the content.');
          return;
        }

        const bapFlashCardModalRef = this.modalService.open(BapFlashCardModalComponent, {
          centered: true,
          windowClass: 'bap-flash-card-modal',
          size: 'xl'
        });
        bapFlashCardModalRef.componentInstance.modal = bapFlashCardModalRef;
        bapFlashCardModalRef.componentInstance.id = data.identifier;

        break;
      case 'SbuxHomeNotification':
        const homeNotificationModalRef = this.modalService.open(NotificationsModalComponent, {
          centered: true,
          windowClass: 'notifications-modal',
          size: 'xl',
          scrollable: true,
          backdrop: 'static'
        });
        homeNotificationModalRef.componentInstance.modal = homeNotificationModalRef;
        homeNotificationModalRef.componentInstance.data = [{
          content_id: flashCardContent.identifier,
          category: flashCardContent.category,
          image_src: {
            sm: flashCardContent.imageMobile ?? '',
            lg: flashCardContent.image
          },
          headline: flashCardContent.title,
          subheadline: flashCardContent.subtitle,
          body: flashCardContent.bodyContents,
          button: {
            label: flashCardContent.ctaLabel,
            link: flashCardContent.ctaLink
          }
        }];
        break;

      default:
        break;
    }
  }

  openUnlockBAPContentModal() {
    const modalRef = this.modalService.open(UnlockBapContentModalComponent, {
      centered: true,
      windowClass: 'unlock-bap-content-modal',
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}


