<div [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="content-heading mb-5">
    <div class="container">
      <h1 class="mb-3">{{"MY_PROFILE.HEADER" | translate}}</h1>
      <div class="descriptions ">
        {{"MY_PROFILE.DESC" | translate}}
      </div>
    </div>
  </section>

  <section *ngIf="isObjectEmpty(data)">
    <div class="container mw-lg">
      <div class="profile mb-5">
        <!-- Green Apron Card -->
        <div *ngIf="data.tier.toUpperCase().includes('GREEN_APRON')" class="partner-profile-card mb-5">
          <div class="d-flex flex-wrap h-100">
            <figure class="flex-auto profile-picture">
              <img *ngIf="data.photo" [src]="data.photo">
              <img *ngIf="!data.photo" src="/assets/images/avatar/img-avatar-placeholder.png">
            </figure>
            <div class="flex-1 d-flex flex-column profile-info h-100 ps-5">
              <h2 class="nickname">{{data.nick_name}}</h2>
              <h5 class="name">{{data.first_name}} {{data.last_name}}</h5>
              <div class="market">{{data.market_name}}</div>
              <div class="email">{{data.email}}</div>
              <div class="join-date mt-auto">
                {{"MY_PROFILE.JOINED_ON_1" | translate}}{{formatDate(data.created_at)}}{{"MY_PROFILE.JOINED_ON_2" | translate}}
              </div>
            </div>
            <div class="logo">
              <img src="/assets/images/global/img-logo-sbx-white.svg" alt="">
            </div>
          </div>
        </div>

        <!-- Black Apron Card -->
        <div *ngIf="data.tier.toUpperCase().includes('BLACK_APRON')" class="partner-profile-card mb-5"
          [class.black-card]="data.tier.toUpperCase().includes('BLACK_APRON')">
          <div class="d-flex flex-wrap h-100">
            <figure class="flex-auto profile-picture">
              <img *ngIf="data.photo" [src]="data.photo">
              <img *ngIf="!data.photo" src="/assets/images/avatar/img-avatar-placeholder.png">
            </figure>
            <div class="flex-1 d-flex flex-column profile-info h-100 ps-5">
              <h2 class="nickname">{{data.nick_name}}</h2>
              <h5 class="name">{{data.first_name}} {{data.last_name}}</h5>
              <div class="market">{{data.market_name}}</div>
              <div class="email">{{data.email}}</div>
              <div class="join-date mt-auto">
                {{"MY_PROFILE.JOINED_ON_1" | translate}}{{formatDate(data.created_at)}}{{"MY_PROFILE.JOINED_ON_2" | translate}}
              </div>
            </div>
            <div class="logo">
              <img src="/assets/images/global/img-logo-sbx-white.svg" alt="">
            </div>
            <div class="black-level-star">
              <img [src]="'/assets/images/profile/black-level-star-'+data.tier.charAt(data.tier.length-1) +'.svg'" />
            </div>
          </div>
        </div>

        <div class="tools mb-4">
          <div class="row">
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <button type="button" class="btn btn-secondary w-100 text-uppercase fw-bold"
                (click)="openChangeProfilePictureModal()">{{"MY_PROFILE.CTA_PP_CHANGE" | translate}}</button>
            </div>
            <div class="col-12 col-md-6 mb-3 mb-md-0">
              <button type="button" class="btn btn-secondary w-100 text-uppercase fw-bold"
                (click)="downloadProfilePicture()"
                [disabled]="!profilePictureUrl">{{"MY_PROFILE.CTA_PP_DOWNLOAD" | translate}}</button>
            </div>
          </div>
        </div>

        <div class="form">
          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-market-input" class="form-label">{{"MY_PROFILE.MARKET" | translate}}</label>
                <input type="text" class="form-control" id="partner-market-input" [value]="data.market_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-id-input" class="form-label">{{"MY_PROFILE.PARTNER_ID" | translate}}</label>
                <input type="text" class="form-control" id="partner-id-input" [value]="data.partner_id" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-fullname-input" class="form-label">{{"MY_PROFILE.FIRST_NAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-fullname-input" [value]="data.first_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-lastname-input" class="form-label">{{"MY_PROFILE.LAST_NAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-lastname-input" [value]="data.last_name" disabled>
              </div>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group">
                <label for="partner-email-input" class="form-label">{{"MY_PROFILE.EMAIL" | translate}}</label>
                <input type="email" class="form-control" id="partner-email-input" [value]="data.email" disabled>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <div class="form-group mb-3">
                <label for="partner-nickname-input" class="form-label">{{"MY_PROFILE.NICKNAME" | translate}}</label>
                <input type="text" class="form-control" id="partner-nickname-input" [value]="data.nick_name" disabled>
              </div>
              <a href="/update-nickname"
                class="btn btn-sm btn-white w-100 w-md-auto">{{"MY_PROFILE.CTA_NICKNAME_CHANGE" | translate}}</a>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <div class="form-group mb-3">
                <label for="partner-password-input"
                  class="form-label">{{"MY_PROFILE.CURRENT_PASSWORD" | translate}}</label>
                <input type="password" class="form-control" id="partner-password-input" value="********" disabled>
              </div>
              <a [href]="'/update-password?e='+encodedEmail"
                class="btn btn-sm btn-white w-100 w-md-auto">{{"MY_PROFILE.CTA_PASSWORD_CHANGE" | translate}}</a>
            </div>
          </div>
        </div>

      </div>

      <!-- <div class="profile-completeness">
        <h3 class="text-dark fw-normal mb-4">{{"MY_PROFILE.PROFILE_COMPLETENESS.TITLE" | translate}}
          {{"MY_PROFILE.PROFILE_COMPLETENESS."+profileCompletenessLevel | translate}}
        </h3>
        <div class="profile-progress">
          <div class="progress-bar">
            <div class="progress-layer" [class.beginner]="profileCompletenessLevel === '25'"
              [class.intermediate]="profileCompletenessLevel === '50'"
              [class.advanced]="profileCompletenessLevel === '75'"
              [class.expert]="profileCompletenessLevel === '100'">
            </div>
            <span class="breakpoint-mark breakpoint-1"></span>
            <span class="breakpoint-mark breakpoint-2"></span>
            <span class="breakpoint-mark breakpoint-3"></span>
          </div>
          <span class="circle-star">
            <icon-circle-star></icon-circle-star>
          </span>
          <span class="circle-star" [class.beginner]="profileCompletenessLevel === '25'"
            [class.intermediate]="profileCompletenessLevel === '50'"
            [class.advanced]="profileCompletenessLevel === '75'" [class.expert]="profileCompletenessLevel === '100'">
            <icon-circle-star></icon-circle-star>
          </span>
        </div>

        <div class="d-none d-md-flex flex-wrap text-center mt-3 fs-small fw-semibold">
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.25" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.50" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.75" | translate}}</div>
          <div class="flex-1">{{"MY_PROFILE.PROFILE_COMPLETENESS.100" | translate}}</div>
        </div>
      </div>



      <div *ngIf="activityData" class="activity mt-5 pt-4 mb-4">
        <h5 class="mb-4 pe-8 text-dark">
          {{"MY_PROFILE.PROFILE_COMPLETENESS.DESC" | translate}}
        </h5>
        <div class="activity-slider">
          <owl-carousel-o [options]="activityCarouselOptions">
            <ng-container *ngFor="let item of activityData">
              <ng-template carouselSlide>
                <div class="activity-item d-flex flex-wrap">
                  <figure class="mt-1">
                    <span class="icon-img">
                      <icon-coffee-bean-bag></icon-coffee-bean-bag>
                    </span>
                  </figure>

                  <div class="content">
                    <h4 class="fw-normal text-dark">{{item.action_name}}</h4>
                    <div class="row align-items-center">
                      <div class="col-12 col-md-8 mb-4 mb-md-0">
                        {{item.description}}
                      </div>
                      <div class="col-12 col-md-4 text-end">
                        <a [href]="item.button.link"
                          class="btn btn-alert btn-sm mt-0 ms-auto w-100 w-md-auto">{{item.button.label}}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

            </ng-container>
          </owl-carousel-o>
        </div>
      </div> -->
    </div>
  </section>
</div>