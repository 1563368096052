import { Component, Renderer2, ElementRef, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BannerCarousel1Component } from "../../components/banner-carousel-1/banner-carousel-1.component";
import { GridList1Component } from "../../components/grid-list-1/grid-list-1.component";
import { GridList2Component } from "../../components/grid-list-2/grid-list-2.component";
import { BannerCarousel2Component } from "../../components/banner-carousel-2/banner-carousel-2.component";
import { ListCarousel1Component } from "../../components/list-carousel-1/list-carousel-1.component";
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ContentService } from '../../services/content.service';
import { BannerCarousel1Model, BannerCarousel2Model, GridList2Model, ListCarousel1Model } from '../../models';
import { CommonModule } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-events',
  standalone: true,
  templateUrl: './events.component.html',
  styleUrl: './events.component.scss',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
  imports: [BannerCarousel1Component, GridList1Component, GridList2Component, BannerCarousel2Component, ListCarousel1Component, BreadcrumbsComponent, TranslateModule, CommonModule]
})
export class EventsComponent implements OnInit {
  eventArchiveContentType: string = 'SbuxEventArchive';
  eventArchiveData: ListCarousel1Model[] = [];
  eventBannerContentType: string = 'SbuxEventBanner';
  eventBannerData: BannerCarousel1Model[] = [];
  eventHighlightContentType: string = 'SbuxEventHighlight';
  eventHighlightData: BannerCarousel2Model[] = [];
  featuredEventContentType: string = 'SbuxFeaturedEvent';
  featuredEventData: GridList2Model[] = [];

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Event & Activities"
    }
  ]

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'flex-1');
    this.renderer.addClass(this.el.nativeElement, 'page-events');

    // Get breadcrumbs translation
    this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.EVENTS']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.EVENTS'];
        this.titleService.setTitle(res['HOME.SHORTCUT.EVENTS'])
      }
    })

    // Event & Activities
    this.contentService.postContent(this.featuredEventContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.featuredEventData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            link: item.link,
            headline: item.title,
            top_label: item.subtitle
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Top Banner
    this.contentService.postContent(this.eventBannerContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          // Banner Object
          const eventBannerObj: BannerCarousel1Model = {
            content_id: item.identifier,
            type: item.type,
            image_src: {
              sm: item.bannerImageMobile,
              lg: item.bannerImage
            },
            headline: item.title,
            descriptions: item.descriptions,
            start_date: item.startDateTime,
            end_date: item.endDateTime
          };

          // Banner Top Subtitle
          if (item.type.toUpperCase() === 'REGULAR' || item.type.toUpperCase() === 'UPCOMING') {
            eventBannerObj.top_label = item.topSubtitle;
          }

          // Banner CTA
          if (item.type.toUpperCase() === 'REGULAR') {
            eventBannerObj.button = {
              link: item.ctaLink,
              label: item.ctaLabel
            }
          }

          // Banner Video Url
          if (item.type.toUpperCase() === 'LIVE') {
            eventBannerObj.button = {
              link: item.videoUrl,
            }
          }
          this.eventBannerData.push(eventBannerObj)
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Event Highlight
    this.contentService.postContent(this.eventHighlightContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.eventHighlightData.push({
            content_id: item.identifier,
            image_src: {
              sm: item.bannerImageMobile,
              lg: item.bannerImage
            },
            link: item.link,
            headline: item.title,
            subheadline: item.bottomSubtitle
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

    // Event Archive
    this.contentService.postContent(this.eventArchiveContentType).subscribe({
      next: res => {
        res.forEach((item: any) => {
          this.eventArchiveData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            headline: item.title,
            descriptions: item.descriptions,
            link: item.link
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }
}
