import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../animation';
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";

@Component({
  selector: 'app-search-results',
  standalone: true,
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, GridList3Component, BreadcrumbsComponent]
})
export class SearchResultsComponent {
  noResults = false;
  hasResults = true
  searchTerm = 'Coffee';

  searchResultDataUrl = '/assets/data/search-results.json';

  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Search Results"
    }
  ]
}


