<div *ngIf="compeititonInformationData" [@fadeInOut] class="inner-body pt-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="heading px-2 py-4 mb-3 text-center">
    <div class="container">
      <div class="d-flex flex-column align-items-center">
        <figure class="logo mb-5">
          <img *ngIf="compeititonInformationData.heading.logo_src" [src]="compeititonInformationData.heading.logo_src"
            [alt]="compeititonInformationData.heading.headline+'-img'">
        </figure>
        <h1>{{compeititonInformationData.heading.headline}}</h1>
        <div class="description">
          {{compeititonInformationData.heading.description}}
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="compeititonInformationData.judging_criteria" class="judging-criteria mb-5">
    <div class="container">
      <div class="file-download-wrapper p-4 py-sm-4 px-sm-5 d-flex flex-wrap">
        <figure class="icon">
          <icon-folder></icon-folder>
        </figure>
        <ul class="list-unstyled file-list mb-0">
          <h4 class="fw-normal text-white mb-3">Download Judging Criteria</h4>
          <li *ngFor="let item of compeititonInformationData.judging_criteria">
            <a [routerLink]="[item.file_src]" target="_blank" class="fs-4 text-white">{{item.display_name}}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6 mb-5 mb-md-0">
          <div class="competition-objective text-center px-2">
            <h2 class="mb-3 text-primary-dark"><span class="fs-1">⭐️</span><br>Objective</h2>
            <div [innerHTML]="compeititonInformationData.competition_objectives | markdown" class="px-md-3 px-lg-4">
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="competition-overview text-center px-2">
            <h2 class="mb-3 text-primary-dark"><span class="fs-1">⏰</span><br>Competition Overview</h2>

            <div class="competition-time mb-4">
              <h4>Competition Time</h4>
              <h4>{{compeititonInformationData.competition_time}}</h4>
            </div>

            <div class="competition-requirements">
              <h4>Competition Requirements</h4>
              <div [innerHTML]="compeititonInformationData.competition_requirements| markdown"
                class="list-centerized px-md-3 px-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="mb-5">
    <div class="container">
      <div class="competition-parts">
        <div *ngFor="let part of compeititonInformationData.competition_parts, index as i" class="part">
          <div class="row">
            <div class="col-12 mb-4">
              <h2 class="text-primary-dark part-name">Part {{i+1}}. {{part.name}}</h2>
            </div>

            <div class="col-12 col-md-6 text-center mb-4 mb-md-0">
              <h2 class="text-primary-dark mb-4"><span class="fs-1">⭐️</span><br>Objective</h2>
              <div [innerHTML]="part.objective| markdown" class="list-centerized px-md-3 px-lg-4"></div>
            </div>

            <div class="col-12 col-md-6 text-center">
              <h2 class="text-primary-dark mb-4"><span class="fs-1">🎯</span><br>Scoring Criteria</h2>
              <div [innerHTML]="part.scoring_criteria| markdown" class="list-centerized px-md-3 px-lg-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>