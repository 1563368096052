import { Component, OnInit, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignUpModalComponent } from '../../components/sign-up-modal/sign-up-modal.component';
import { fadeInOut } from '../../animation';
import { SignInModalComponent } from '../../components/sign-in-modal/sign-in-modal.component';
import { SingleBannerComponent } from "../../components/single-banner/single-banner.component";
import { GridList3Component } from "../../components/grid-list-3/grid-list-3.component";
import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { ContentService } from '../../services/content.service';
import { GridList3Model, SingleBannerModel } from '../../models';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-public-home',
  standalone: true,
  templateUrl: './public-home.component.html',
  styleUrl: './public-home.component.scss',
  animations: [fadeInOut],
  imports: [SingleBannerComponent, GridList3Component, TranslateModule, CommonModule]
})
export class PublicHomeComponent implements OnInit {
  articleData: GridList3Model[] = [];
  bannerData!: SingleBannerModel;
  publicHomeBannerContentType = 'SbuxPublicHomeBanner';
  publicHomeFeaturesContentType = 'SbuxPublicHomeArticle';

  constructor(
    private modalService: NgbModal,
    private contentService: ContentService,
    public configService: ConfigService
  ) { }

  ngOnInit(): void {
    this.contentService.postContent(this.publicHomeBannerContentType, undefined, undefined, undefined, 'null').subscribe({
      next: res => {
        this.bannerData = {
          content_id: res[0].identifier,
          image_src: {
            sm: res[0].bannerImageMobile,
            lg: res[0].bannerImage
          },
          headline: res[0].title,
          descriptions: res[0].description
        }
      },
      error: err => {
        console.log(err);
      }
    });

    this.contentService.postContent(this.publicHomeFeaturesContentType).subscribe({
      next: res => {
        res.slice(0, 3).forEach((item: any) => {
          this.articleData.push({
            content_id: item.identifier,
            image_src: item.coverImage,
            headline: item.title,
            descriptions: item.description
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });
  }

  openSignUpModal() {
    const modalRef = this.modalService.open(SignUpModalComponent, {
      centered: true,
      windowClass: 'sign-up-modal',
      size: 'xl',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
  }

  openSignInModal() {
    const modalRef = this.modalService.open(SignInModalComponent, {
      centered: true,
      windowClass: 'sign-in-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}
