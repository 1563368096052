import { Component, OnInit } from '@angular/core';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { BookmarkComponent } from "../../components/bookmark/bookmark.component";
import { PlayIcon } from "../../icons/";
import { FullscreenBannerComponent } from "../../components/fullscreen-banner/fullscreen-banner.component";
import { VideoListComponent } from "../../components/video-list/video-list.component";
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../../services/content.service';
import { FullScreenBannerModel, VideoListModel } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AnalyticsService } from '../../services/analytics.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-learning-topic',
  standalone: true,
  imports: [BreadcrumbsComponent, FullscreenBannerComponent, VideoListComponent, CommonModule],
  templateUrl: './learning-topic.component.html',
  styleUrl: './learning-topic.component.scss',
  animations: [fadeInOut],
})
export class LearningTopicComponent implements OnInit {
  topicIdentifier!: string;
  fullscreenBannerData!: FullScreenBannerModel;
  videoListData: VideoListModel[] = [];

  learningTopicContentType = 'SbuxLearningTopic';
  breadcrumbsData = [
    {
      type: 'IS_BACK_LINK',
      link: '/learn',
      label: 'Learning Centre'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contentService: ContentService,
    private translation: TranslateService,
    private titleService: Title,
    private analyticsService: AnalyticsService,
    private configService: ConfigService
  ) {
    this.translation.get(['GENERAL.MENU.LEARNING_CENTRE']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.LEARNING_CENTRE'];
      }
    })
  }

  ngOnInit(): void {
    // Get Url param
    this.route.params.subscribe(params => {
      this.topicIdentifier = params['pId'];

      // Add Content Analytic
      if (!this.configService.isPreview) {
        this.analyticsService.createContentAnalytic(this.topicIdentifier, this.learningTopicContentType, this.router.url);
      }
    });

    this.contentService.postContent(this.learningTopicContentType, this.topicIdentifier).subscribe({
      next: res => {
        const data = res[0];

        this.titleService.setTitle(data.title);

        this.fullscreenBannerData = {
          content_id: data.identifier,
          image_src: {
            sm: data.heroBannerMobile,
            lg: data.heroBanner
          },
          overlay_headline: data.title,
          overlay_subheadline: data.descriptions
        }

        data.lessonList.forEach((item: any) => {
          const splitHeadline = item.title.split('|');
          this.videoListData.push({
            content_id: item.identifier,
            cover_image_src: item.coverImage,
            link: (item.type === 'Article' ? `/articles/` : `/videos/`) + item.identifier,
            headline: splitHeadline[0],
            headline_2: splitHeadline[1] ?? '',
            type: item.type,
            descriptions: item.descriptions,
            video_title: item.title,
            video_duration: this.contentService.formatSecToHMS(item.videoDuration),
            mark_as_completed: false // future
          })
        });
      },
      error: err => {
        console.log(err);
      }
    });

  }
}
