import { Component, inject, TemplateRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbOffcanvas, NgbOffcanvasRef, NgbCollapseModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ArrowLeftIcon, ArrowRightIcon, XmarkIcon, SearchIcon, MenuBarsIcon, InstagramIcon, SCCLogo, PageviewIcon, ExclamationCircleSolidIcon } from '../../icons';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignInModalComponent } from '../sign-in-modal/sign-in-modal.component';
import { LanguageService } from '../../services/language.service';
import { AuthService } from '../../services/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { AccountService } from '../../services/account.service';
import { environment } from '../../../environment/environment';
import { ConfigService } from '../../services/config.service';
import { RouterLink } from '@angular/router';


@Component({
	selector: 'app-header',
	standalone: true,
	imports: [
		CommonModule,
		NgbCollapseModule,
		NgbDropdownModule,
		ArrowLeftIcon,
		ArrowRightIcon,
		XmarkIcon,
		SearchIcon,
		MenuBarsIcon,
		InstagramIcon,
		SCCLogo,
		CommonModule,
		TranslateModule,
		RouterLink,
		PageviewIcon,
		ExclamationCircleSolidIcon
	],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
	private offcanvasService = inject(NgbOffcanvas);
	closeResult = '';
	languageShortForm: string = '';
	profilePicture!: string;
	isSearchCollapsed = true;
	isInCanvasLanguageMenuVisible: boolean = false;
	currentEventUrl: string = environment.RBC2024;

	constructor(
		private breakpointObserver: BreakpointObserver,
		private modalService: NgbModal,
		private languageService: LanguageService,
		private authService: AuthService,
		private accountService: AccountService,
		public configService: ConfigService
	) {
		const breakpointLG = this.breakpointObserver.observe('(min-width: 992px)');
		// Subscribe to window width changes
		breakpointLG.subscribe(result => {
			if (result.matches) {
				// If the screen width is greater than or equal to 992 pixels
				this.isInCanvasLanguageMenuVisible = false;
			}
		});
	}

	ngOnInit(): void {
		if (!this.configService.isPreview) {
			this.accountService.getMyProfile().subscribe({
				next: res => {
					this.profilePicture = res.photo;
					localStorage.setItem('languageId', res.cms_language_id);
					this.languageShortForm = this.languageService.languageMap(res.cms_language_id);
				}
			})
		}
	}

	handleClick(event: MouseEvent, languageId: string) {
		event.preventDefault(); // Prevent <a> redirect
		this.serverSwitchLanguage(languageId);
	}

	logout() {
		this.authService.userServerLogout();
	}

	open(offcanvasContent: TemplateRef<any>) {
		const offcanvasRef: NgbOffcanvasRef = this.offcanvasService.open(offcanvasContent);

		// Subscribe to the 'hidden' event to perform actions when the offcanvas is hidden
		offcanvasRef.hidden.subscribe(() => {
			this.isInCanvasLanguageMenuVisible = false;
			// Perform actions when offcanvas is hidden
		});
	}

	openSignInModal() {
		const modalRef = this.modalService.open(SignInModalComponent, {
			centered: true,
			windowClass: 'sign-in-modal',
			size: 'xl'
		});
		modalRef.componentInstance.modal = modalRef;
	}

	serverSwitchLanguage(language: string): void {
		this.languageService.serverSwitchLanguage(language);
	}

	toggleInCanvasLanguageMenu() {
		this.isInCanvasLanguageMenuVisible = !this.isInCanvasLanguageMenuVisible;
	}
}
