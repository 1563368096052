import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { fadeInOut } from '../../animation';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CircleInfoIcon } from "../../icons/icon-circle-info";
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import passwordValidatorCriteria from '../../validators/password-validator';
import { CommonModule } from '@angular/common';
import { AuthService } from '../../services/auth.service';
import { AccountService } from '../../services/account.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorService } from '../../services/error.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-update-password',
  standalone: true,
  imports: [RouterModule, NgbTooltipModule, CircleInfoIcon, ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss',
  animations: [fadeInOut]
})
export class UpdatePasswordComponent implements OnInit, AfterViewInit {
  @ViewChild('tooltipButton') tooltipEl!: NgbTooltip;
  emailParam!: string;
  errorList!: string[];
  email!: string;
  isSubmitting: boolean = false;
  matchPassword: boolean = true;
  updatePasswordForm!: FormGroup;
  username!: string; // will remove once server implemented
  usernameList: string[] = []; // will remove once server implemented
  serverError: boolean = false;
  serverErrorMessage!: string;
  frontendError: boolean = false;
  frontendErrorMessage!: string;
  currentPasswordError: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private authService: AuthService,
    private errorService: ErrorService,
    private translation: TranslateService,
    private titleService: Title
  ) {
    translation.get(['UPDATE_PASSWORD.HEADER']).subscribe({
      next: res => {
        titleService.setTitle(res['UPDATE_PASSWORD.HEADER']);
      }
    })
  }

  ngAfterViewInit(): void {
    window.addEventListener('message', (event) => {
      if (event.data === 'invalidPassword') {
        this.tooltipEl.open();
      }
    })
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.emailParam = params['e'];
    });

    this.updatePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, this.passwordValidator()]),
      confirmPassword: new FormControl('', [Validators.required]),
    })

    // Get username in a list
    this.email = this.emailParam ? atob(this.emailParam) : "";
    this.username = this.email.split('@')[0];
    this.usernameList = this.username.split(/[^\w\s]|_/);

    // Only return characters length > 3
    this.usernameList = this.usernameList.filter(function (item) {
      return item.length >= 3;
    });
  }

  onSubmit() {
    this.updatePasswordForm.disable();
    this.isSubmitting = true;

    //  if confirmPassword does not match password
    if (this.updatePasswordForm.controls['password'].value !== this.updatePasswordForm.controls['confirmPassword'].value) {
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5001');
      this.matchPassword = false;
      this.isSubmitting = false;
      this.updatePasswordForm.enable();
      return;
    }

    // if frontend validation fail
    if (this.errorList.length > 0) {
      this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
      this.frontendError = true;
      window.postMessage('invalidPassword');
      this.isSubmitting = false;
      this.updatePasswordForm.enable();
      return;
    }

    // =================================== Validate Password ========================================
    const body = {
      'email': this.email,
      'password': this.updatePasswordForm.controls['password'].value
    }

    // Test call from AuthService
    this.authService.validatePassword(body).subscribe({
      next: res => {
        // Update Password POST Request
        this.accountService.updatePassword(this.updatePasswordForm.controls['currentPassword'].value, this.updatePasswordForm.controls['password'].value).subscribe({
          next: res => {
            if (res.data) {
              // Log user in
              this.authService.userAuth(this.email, this.updatePasswordForm.controls['password'].value).subscribe({
                next: res => {
                  // Set userToken in localstorage
                  this.authService.userAuthSuccess(res);
                },
                complete: () => {
                  this.router.navigate(['/reset-password-successful']);
                }
              });
            } else if (res.error_code !== '-1') {

              if (res.error_code === '1018') { // Current Password Error
                this.currentPasswordError = true;
              }
              this.serverErrorMessage = this.errorService.getServerErrorMapping(res.error_code);
              this.serverError = true;
            } else {
              this.frontendErrorMessage = this.errorService.getFrontendErrorMapping('5000');
              this.frontendError = true;
              window.postMessage('invalidPassword');
            }
          },
          error: err => {
            console.log(err);
          }
        });
      },
      error: err => {
        console.log(err);

        window.postMessage('invalidPassword');
        this.frontendError = true;
        this.isSubmitting = false;
        this.updatePasswordForm.enable();
      },
      complete: () => {
        this.isSubmitting = false;
        this.updatePasswordForm.enable();
      }
    })
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const password: string = control.value;

      // Password validator criteria
      this.errorList = passwordValidatorCriteria(password, this.usernameList);

      if (this.errorList.length >= 1) {
        const firstError = this.errorList[0];
        return {
          [firstError]: true
        }
      }
      return null;
    }
  }
}
