<div class="modal-body">
  <button type="button" class="btn btn-ghost btn-close-modal" aria-label="Close" (click)="dismissModal()">
    <span class="icon-img">
      <icon-xmark></icon-xmark>
    </span>
  </button>

  <div class="modal-inner-wrapper">
    <div class="modal-inner-header">
      <figure>
        <img *ngIf="bapTopicItemData.image_src.sm" [src]="bapTopicItemData.image_src.sm" class="d-md-none"
          alt="flash-card-img" />
        <img [src]="bapTopicItemData.image_src.lg" [ngClass]="{'d-none d-md-block': bapTopicItemData.image_src.sm}"
          alt="flash-card-img" />
      </figure>
    </div>
    <div class="modal-inner-body">
      <div class="inner-body-heading">
        <h6 class="text-dark">{{ "BAP.HEADER" | translate }} / {{bapTopicItemData.title}}</h6>
        <h2>{{bapTopicItemData.title}}</h2>
      </div>

      <div class="inner-body-contents custom-scrollbar">
        <div [innerHTML]="bapTopicItemData.content"></div>
      </div>
    </div>
  </div>
</div>