import { Injectable } from '@angular/core';
import { environment } from '../../environment/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthResponse } from '../models/auth-response.model';
import { Router } from '@angular/router';
import { Observable, catchError, map, throwError } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    toastrErrorHeader!: string;
    toastrErrorDesc!: string;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) { }

    clientAuth(token: string): Promise<boolean> {
        if (token) {
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        }

        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        if (environment.clientAuthEnabled) {
            reqHeader = reqHeader.append("Authorization", `Basic ${environment.clientApiKey}`)
        }

        const body = new URLSearchParams();
        body.set('grant_type', 'client_credentials');

        return new Promise((resolve, reject) => {
            this.http
                .post<AuthResponse>(
                    `${environment.domain}/oauth/token`,
                    body.toString(),
                    { headers: reqHeader }
                )
                .subscribe({
                    next: (response) => {
                        localStorage.setItem('auth', JSON.stringify(response));
                        resolve(true);
                    },
                    error: (err) => {
                        reject(err);
                    }
                });
        });
    }

    userAuth(username: string, password: string): Observable<any> {
        let reqHeader = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        });
        if (environment.clientAuthEnabled) {
            reqHeader = reqHeader.append("Authorization", `Basic ${environment.clientApiKey}`)
        }
        const body = new URLSearchParams();
        body.set('grant_type', 'password');
        body.set('username', username);
        body.set('password', password);

        return this.http.post<any>(environment.domain + "/oauth/token", body.toString(), { headers: reqHeader })
            .pipe(
                catchError((error) => {
                    return throwError(() => error); // Re-throw the error to propagate it further
                }),
                map(res => {
                    return res;
                })
            );
    }

    getClientAccessToken(): string {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const authJson = JSON.parse(auth);
            return authJson.access_token;
        }
        return '';
    }

    getUserAccessToken(): string {
        const auth = localStorage.getItem('auth');
        if (auth) {
            const authJson = JSON.parse(auth);

            // is logged in
            if (authJson.refresh_token) {
                return authJson.access_token;
            }
        }
        return '';
    }

    userAuthSuccess(data: object): void {
        localStorage.setItem('auth', JSON.stringify(data));
    }

    // Invalidate Access Token
    userServerLogout(): void {
        this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.accountPath}/logout`, "").subscribe({
            next: res => {
                this.userLogout();
            }
        });
    }

    userLogout(): void {
        localStorage.removeItem('auth');
        window.location.reload();
    }

    signUp(body: object): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/signup`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        )
    }


    // ========================================================================= FORM VALIDATION ============================================================================
    // ======================================================================================================================================================================

    validateSecretCode(code: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const body = {
            'code': code
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/secret-code/validate`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        )
    }

    validatePassword(body: object): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/password/validate`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        )
    }

    validatePartnerId(id: string, marketCode: string): Observable<any> {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            'id': id,
            'market_code': marketCode
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/partner-id/validate`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        );
    }

    checkEmailExist(email: string) {
        const reqHeader = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = {
            'email': email
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/partner-email/exists`, body, { headers: reqHeader }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // ====================================================================== FORMAT VALIDATION =========================================================================
    // ==================================================================================================================================================================

    // Validate Email Format
    validateEmail(email: string) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Not email
        if (!emailRegex.test(email)) {
            return false;
        }
        return true;
    }

    // ====================================================================== ACTIVATE ACCOUNTS ==========================================================================
    // ===================================================================================================================================================================

    // Validate Activate Account Email Link
    verifyEmail(body: object, header: HttpHeaders): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/activate-account`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // ====================================================================== RESET PASSWORD ===========================================================================
    // =================================================================================================================================================================

    // Request Reset Password
    requestResetPassword(body: object, header: HttpHeaders): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/reset-password/request`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Verify Reset Password Email Link
    verifyResetPassword(body: object, header: HttpHeaders): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/reset-password/verify`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Submit New Password
    submitResetPassword(body: object, header: HttpHeaders): Observable<any> {
        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.authPath}/reset-password/submit`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }
}