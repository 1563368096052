<div [@fadeInOut] class="inner-body p-0">
  <div class="breadcrumbs-container">
    <div class="container">
      <app-breadcrumbs [data]="breadcrumbsData"></app-breadcrumbs>
    </div>
  </div>

  <section class="hero-banner mb-5">
    <div class="container">
      <app-image-banner-1 [data]="imageBannerData"></app-image-banner-1>
    </div>
  </section>

  <section class="content-body mb-5">
    <div class="container mw-lg">
      <div [innerHTML]="sanitizeStyle(bapTopicItemArticleContent)"></div>
    </div>
  </section>
</div>