import { Component, Renderer2, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component'
import { FooterComponent } from "./components/footer/footer.component";
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { LanguageService } from './services/language.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../environment/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
})

export class AppComponent implements OnInit {
  isLoaded: boolean = false;
  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private translate: TranslateService,
    private authService: AuthService,
    private languageService: LanguageService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    if (environment.clientAuthEnabled) {
      this.authService.clientAuth(this.authService.getClientAccessToken())
        .then(res => this.isLoaded = res)
        .catch((err) => {
          this.toastr.warning('Unable to reach the server, please refresh your browser.');
        });
    } else {
      this.isLoaded = true;
    }

    // Load locale language (default to EN)
    const lanugageId = this.languageService.getLanguage();
    if (lanugageId === null) {
      localStorage.setItem('languageId', '1');
    } else {
      this.translate.use(this.languageService.languageMapping[Number(lanugageId)]);
    }

    // Update index.html lang attribute
    document.documentElement.lang = this.languageService.languageHTMLMap(lanugageId ?? '1');

    // Sync Language across tabs (same browser)
    window.addEventListener('storage', (event) => {
      if (event.key === 'languageId') {
        this.languageService.switchLanguage(event.newValue ?? '1');
      }
    })

    // Listen to avatar module onload (Onboarding & Change Profile Picture)
    window.addEventListener('message', (event: MessageEvent) => {
      // Pass language preference to avatar module
      if (event.data.type === 'isReady') {
        const languageId = this.languageService.getLanguage() ?? '1';
        const languageCode = this.languageService.languageMapping[Number(languageId)];
        const frame = document.querySelector("iframe");
        frame!.contentWindow!.postMessage({ type: 'languageCode', data: languageCode }, "*"); // Send languageCode to Avatar Module
      }
    })

    this.renderer.addClass(this.el.nativeElement, 'app-root');
  }

  title = 'Starbucks Coffee Community';
}
