import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { EventsComponent } from './pages/events/events.component';
import { PublicHomeComponent } from './pages/public-home/public-home.component';
import { RegistrationSuccessfulComponent } from './pages/registration-successful/registration-successful.component';
import { ResetPasswordSuccessfulComponent } from './pages/reset-password-successful/reset-password-successful.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { SearchResultsComponent } from './pages/search-results/search-results.component';
import { EventComponent } from './pages/event/event.component';
import { WorkshopDetailsComponent } from './pages/workshop-details/workshop-details.component';
import { EventScheduleComponent } from './pages/event-schedule/event-schedule.component';
import { VideoPageComponent } from './pages/video-page/video-page.component';
import { MeetTheBaristaAndJudgesComponent } from './pages/meet-the-barista-and-judges/meet-the-barista-and-judges.component';
import { CompetitionVideosComponent } from './pages/competition-videos/competition-videos.component';
import { CompetitionSteeringCommitteeComponent } from './pages/competition-steering-committee/competition-steering-committee.component';
import { userAuthGuard } from './helpers/user-auth.guard';
import { clientAuthGuard } from './helpers/client-auth.guard';
import { LearningTopicComponent } from './pages/learning-topic/learning-topic.component';
import { EventVideosComponent } from './pages/event-videos/event-videos.component';
import { CompetitionInformationComponent } from './pages/competition-information/competition-information.component';
import { CompetitionsComponent } from './pages/competitions/competitions.component';
import { WorkshopsComponent } from './pages/workshops/workshops.component';
import { BapTopicComponent } from './pages/bap-topic/bap-topic.component';
import { BapArticleComponent } from './pages/bap-article/bap-article.component';
import { BapWorkshopDetailsComponent } from './pages/bap-workshop-details/bap-workshop-details.component';
import { LearningCentreComponent } from './pages/learning-centre/learning-centre.component';
import { LearningArticleComponent } from './pages/learning-article/learning-article.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { MyBookmarkComponent } from './pages/my-bookmark/my-bookmark.component';
import { MyWorkshopComponent } from './pages/my-workshop/my-workshop.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { UpdateNicknameComponent } from './pages/update-nickname/update-nickname.component';
import { EmailPartnersComponent } from './pages/email-partners/email-partners.component';
import { FaqComponent } from './pages/faq/faq.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { AboutBapComponent } from './pages/about-bap/about-bap.component';
import { ActivationComponent } from './pages/activation/activation.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ConditionalLayoutComponent } from './layouts/conditional-layout/conditional-layout.component';
import { BapStaticComponent } from './pages/bap-static/bap-static.component';
import { BaristaChampionshipComponent } from './pages/competition-information/barista-championship/barista-championship.component';
import { CoffeeStoriesChampionshipComponent } from './pages/competition-information/coffee-stories-championship/coffee-stories-championship.component';
import { LatteArtChampionshipComponent } from './pages/competition-information/latte-art-championship/latte-art-championship.component';
import { ManualBrewCompetitionComponent } from './pages/competition-information/manual-brew-competition/manual-brew-competition.component';
import { ManualEspressoCompetitionComponent } from './pages/competition-information/manual-espresso-competition/manual-espresso-competition.component';
import { UltimateBaristaTeamChallengeComponent } from './pages/competition-information/ultimate-barista-team-challenge/ultimate-barista-team-challenge.component';
import { HiddenPageComponent } from './pages/hidden-page/hidden-page.component';
import { BapComponent } from './pages/bap/bap.component';
import { previewAuthGuard } from './helpers/preview-auth.guard';
import { PreviewPersonComponent } from './pages/preview-person/preview-person.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ConditionalLayoutComponent,
    canActivate: [previewAuthGuard],
    children: [
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'email-partners',
        component: EmailPartnersComponent
      },
      {
        path: 'reset-password-successful',
        component: ResetPasswordSuccessfulComponent
      }
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [userAuthGuard, previewAuthGuard],
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'events',
        component: EventsComponent
      },
      {
        path: 'events/:pId',
        component: EventComponent
      },
      { // Explore list: Event
        path: 'events/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'events/:pId/event-schedule',
        component: EventScheduleComponent
      },
      {
        path: 'events/:pId/activities/:aId',
        component: EventVideosComponent
      },
      {
        path: 'events/:eId/activities/:pId/meet-the-barista-and-judges',
        component: MeetTheBaristaAndJudgesComponent
      },
      {
        path: 'events/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      { // No use case right now
        path: 'events/:pId/event-schedule/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'events/:eId/activities/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'competitions',
        component: CompetitionsComponent
      },
      {
        path: 'competitions/informations/barista-championship',
        component: BaristaChampionshipComponent
      },
      {
        path: 'competitions/informations/coffee-stories-championship',
        component: CoffeeStoriesChampionshipComponent
      },
      {
        path: 'competitions/informations/latte-art-championship',
        component: LatteArtChampionshipComponent
      },
      {
        path: 'competitions/informations/manual-brew-competition',
        component: ManualBrewCompetitionComponent
      },
      {
        path: 'competitions/informations/manual-espresso-competition',
        component: ManualEspressoCompetitionComponent
      },
      {
        path: 'competitions/informations/ultimate-barista-team-challenge',
        component: UltimateBaristaTeamChallengeComponent
      },
      { // No use case
        path: 'competitions/informations/:compInfoId',
        component: CompetitionInformationComponent
      },
      {
        path: 'competitions/competition-steering-committee',
        component: CompetitionSteeringCommitteeComponent
      },
      {
        path: 'competitions/:pId',
        component: CompetitionVideosComponent
      },
      {
        path: 'competitions/:pId/meet-the-barista-and-judges',
        component: MeetTheBaristaAndJudgesComponent
      },
      {
        path: 'competitions/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'learn',
        component: LearningCentreComponent
      },
      {
        path: 'learn/:pId',
        component: LearningTopicComponent,
      },
      { // Explore List: Learning Lesson (video)
        path: 'learn/videos/:contentId',
        component: VideoPageComponent
      },
      { // Explore List: Learning Lesson (article)
        path: 'learn/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'learn/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'learn/:pId/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'workshops',
        component: WorkshopsComponent
      },
      {
        path: 'workshops/articles/:contentId',
        component: WorkshopDetailsComponent
      },
      {
        path: 'workshops/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'bap',
        component: BapStaticComponent
      },
      {
        path: 'bap-actual',
        component: BapComponent
      },
      {
        path: 'bap-actual/:contentId',
        component: BapTopicComponent
      },
      {
        path: 'bap-actual/:pId/bap-articles/:contentId',
        component: BapArticleComponent
      },
      {
        path: 'bap-actual/:pId/bap-workshop-details/:contentId',
        component: BapWorkshopDetailsComponent
      },
      {
        path: 'about-bap',
        component: AboutBapComponent
      },
      {
        path: 'search-results',
        component: SearchResultsComponent
      },
      {
        path: 'my-profile',
        component: MyProfileComponent
      },
      {
        path: 'my-bookmark/:contentType/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'my-bookmark/:contentType/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'my-bookmark',
        component: MyBookmarkComponent
      },
      {
        path: 'my-workshop',
        component: MyWorkshopComponent
      },
      {
        path: 'my-workshop/:contentId',
        component: WorkshopDetailsComponent
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent
      },
      {
        path: 'update-nickname',
        component: UpdateNicknameComponent
      },
      // {
      //   path: 'component-list',
      //   component: ComponentListComponent
      // },
      // {
      //   path: 'web-style-guide',
      //   component: WebStyleGuideComponent
      // },
      // Other routes...
      { // Hidden page for debug
        path: 'events/:pId/hidden/:contentId',
        component: HiddenPageComponent
      },
      { // Hidden page for debug
        path: 'events/:eId/activities/:pId/hidden/:contentId',
        component: HiddenPageComponent
      },
      { // Hidden page for debug
        path: 'competitions/:pId/hidden/:contentId',
        component: HiddenPageComponent
      },
      { // Hidden page for debug
        path: 'learn/:pId/hidden/:contentId',
        component: HiddenPageComponent
      },
      { // Hidden page for debug
        path: 'workshops/hidden/:contentId',
        component: HiddenPageComponent
      },
      { // Hidden page for debug
        path: 'my-bookmark/:contentType/hidden/:contentId',
        component: HiddenPageComponent
      },
    ]
  },
  { // admin preview link
    path: 'preview',
    component: MainLayoutComponent,
    canActivate: [previewAuthGuard],
    children: [
      { // redirect
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      { // public pages
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'public',
        component: PublicHomeComponent
      },
      { // partner pages
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'events',
        component: EventsComponent
      },
      { // Explore list: Event
        path: 'events/videos/:contentId',
        component: VideoPageComponent
      },
      { // Preview page: Event Activity
        path: 'events/activities/:aId',
        component: EventVideosComponent
      },
      {
        path: 'events/event-schedule',
        component: EventScheduleComponent
      },
      {
        path: 'events/:pId',
        component: EventComponent
      },
      {
        path: 'events/:pId/event-schedule',
        component: EventScheduleComponent
      },
      {
        path: 'events/:pId/activities/:aId',
        component: EventVideosComponent
      },
      {
        path: 'events/:eId/activities/:pId/meet-the-barista-and-judges',
        component: MeetTheBaristaAndJudgesComponent
      },
      {
        path: 'events/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      { // No use case right now
        path: 'events/:pId/event-schedule/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'events/:eId/activities/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'competitions',
        component: CompetitionsComponent
      },
      {
        path: 'competitions/informations/barista-championship',
        component: BaristaChampionshipComponent
      },
      {
        path: 'competitions/informations/coffee-stories-championship',
        component: CoffeeStoriesChampionshipComponent
      },
      {
        path: 'competitions/informations/latte-art-championship',
        component: LatteArtChampionshipComponent
      },
      {
        path: 'competitions/informations/manual-brew-competition',
        component: ManualBrewCompetitionComponent
      },
      {
        path: 'competitions/informations/manual-espresso-competition',
        component: ManualEspressoCompetitionComponent
      },
      {
        path: 'competitions/informations/ultimate-barista-team-challenge',
        component: UltimateBaristaTeamChallengeComponent
      },
      { // No use case
        path: 'competitions/informations/:compInfoId',
        component: CompetitionInformationComponent
      },
      {
        path: 'competitions/competition-steering-committee',
        component: CompetitionSteeringCommitteeComponent
      },
      { // Preview page : Competition Video
        path: 'competitions/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'competitions/:pId',
        component: CompetitionVideosComponent
      },
      {
        path: 'competitions/:pId/meet-the-barista-and-judges',
        component: MeetTheBaristaAndJudgesComponent
      },
      {
        path: 'competitions/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'learn',
        component: LearningCentreComponent
      },
      {
        path: 'learn/:pId',
        component: LearningTopicComponent,
      },
      { // Explore List: Learning Lesson (video)
        path: 'learn/videos/:contentId',
        component: VideoPageComponent
      },
      { // Explore List: Learning Lesson (article)
        path: 'learn/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'learn/:pId/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'learn/:pId/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'workshops',
        component: WorkshopsComponent
      },
      {
        path: 'workshops/articles/:contentId',
        component: WorkshopDetailsComponent
      },
      {
        path: 'workshops/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'bap',
        component: BapStaticComponent
      },
      {
        path: 'bap-actual',
        component: BapComponent
      },
      { // Preview page : BAP Article Topic Item
        path: 'bap-actual/bap-articles/:contentId',
        component: BapArticleComponent
      },
      { // Preview page : BAP Workshop Topic Item
        path: 'bap-actual/bap-workshop-details/:contentId',
        component: BapWorkshopDetailsComponent
      },
      {
        path: 'bap-actual/:contentId',
        component: BapTopicComponent
      },
      {
        path: 'bap-actual/:pId/bap-articles/:contentId',
        component: BapArticleComponent
      },
      {
        path: 'bap-actual/:pId/bap-workshop-details/:contentId',
        component: BapWorkshopDetailsComponent
      },
      {
        path: 'about-bap',
        component: AboutBapComponent
      },
      {
        path: 'search-results',
        component: SearchResultsComponent
      },
      {
        path: 'my-profile',
        component: MyProfileComponent
      },
      {
        path: 'my-bookmark/:contentType/articles/:contentId',
        component: LearningArticleComponent
      },
      {
        path: 'my-bookmark/:contentType/videos/:contentId',
        component: VideoPageComponent
      },
      {
        path: 'my-bookmark',
        component: MyBookmarkComponent
      },
      {
        path: 'my-workshop',
        component: MyWorkshopComponent
      },
      {
        path: 'my-workshop/:contentId',
        component: WorkshopDetailsComponent
      },
      {
        path: 'person',
        component: PreviewPersonComponent
      },
      {
        path: '**',
        redirectTo: 'home',
      }
    ]
  },
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [clientAuthGuard, previewAuthGuard],
    children: [
      {
        path: 'public',
        component: PublicHomeComponent
      },
      {
        path: 'activate-account',
        component: ActivationComponent
      },
      {
        path: 'registration-successful',
        component: RegistrationSuccessfulComponent
      },
      // {
      //   path: 'setup-new-password',
      //   component: SetupNewPasswordComponent
      // },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/home'
  }
];
