import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { fadeInOut } from '../../animation';
import { BreadcrumbsComponent } from "../../components/breadcrumbs/breadcrumbs.component";
import { ImageBanner1Component } from "../../components/image-banner-1/image-banner-1.component";
import { GridList1Component } from "../../components/grid-list-1/grid-list-1.component";
import { ListCarousel2Component } from "../../components/list-carousel-2/list-carousel-2.component";
import { ListCarousel1Component } from "../../components/list-carousel-1/list-carousel-1.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ContentService } from '../../services/content.service';
import { GridList1Model, ListCarousel1Model } from '../../models';
import { BapFlashCardModalComponent } from '../../components/bap-flash-card-modal/bap-flash-card-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UnlockBapContentModalComponent } from '../../components/unlock-bap-content-modal/unlock-bap-content-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-bap',
  standalone: true,
  templateUrl: './bap.component.html',
  styleUrl: './bap.component.scss',
  animations: [fadeInOut],
  imports: [CommonModule, BreadcrumbsComponent, ImageBanner1Component, GridList1Component, ListCarousel2Component, ListCarousel1Component, TranslateModule]
})
export class BapComponent implements OnInit, OnDestroy {
  breadcrumbsData = [
    {
      "link": "/home",
      "label": "Home"
    },
    {
      "link": "",
      "label": "Black Apron Program"
    }
  ]

  bapTopicContentType: string = 'SbuxBapTopic';
  bapTopicSection: any[] = [];
  bapTopicData: GridList1Model[] = [];

  imageBanner1Data = {
    content_id: "bap-banner",
    image_src: {
      sm: "",
      lg: "/assets/images/banner/img-banner-bap.webp"
    }
  };

  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translation: TranslateService,
    private titleService: Title,
    private contentService: ContentService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dark-theme');

    // Get breadcrumbs translation
    // this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.BAP', 'BAP.BREW_CRAFT', 'BAP.ROAST_BLEND', 'BAP.ORIGIN_ETHICAL_SOURCING', 'BAP.COFFEE_LEADERSHIP', 'BAP.ABOUT']).subscribe({
    this.translation.get(['GENERAL.MENU.HOME', 'HOME.SHORTCUT.BAP']).subscribe({
      next: res => {
        this.breadcrumbsData[0].label = res['GENERAL.MENU.HOME'];
        this.breadcrumbsData[1].label = res['HOME.SHORTCUT.BAP'];
        this.titleService.setTitle(res['HOME.SHORTCUT.BAP']);
      }
    })

    this.contentService.postContent(this.bapTopicContentType).subscribe({
      next: res => {
        if (res.length > 0) {
          res.forEach((topic: any) => {

            // Set BAP Topic Data (Cover listing)
            this.bapTopicData.push({
              "content_id": `${topic.title}-content-id`,
              "image_src": topic.coverImage,
              "link": `/bap-actual/${topic.identifier}`,
              "headline": topic.title
            })

            // Set BAP (Topic section)
            let bapTopicItem: ListCarousel1Model[] = [];
            if (topic.topicItem && topic.topicItem.length > 0) {
              topic.topicItem.forEach((topicItem: any) => {
                let bapTopicItemType: string = topicItem.type ?? topicItem.type1 ?? '';
                bapTopicItem.push({
                  "content_id": `${topicItem.title}-content-id`,
                  "identifier": topicItem.identifier,
                  "type": bapTopicItemType.toUpperCase() === "FLASH CARD" ? "FLASHCARD" : "REGULAR",
                  "image_src": topicItem.coverImage,
                  "link": `bap-actual/${topic.identifier}/${this.bapTopicItemNavigationUrl(bapTopicItemType, topicItem.identifier)}`,
                  "headline": topicItem.title,
                  "descriptions": topicItem.descriptions,
                  "level_requirement": topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase(),
                  "disabled": this.configService.isPreview ? false : !this.contentService.canViewContent(topicItem.blackApronExclusive.replace(/ /g, '_').toUpperCase())
                });
              });

              this.bapTopicSection.push({
                title: topic.title,
                topic: bapTopicItem
              })
            }
          });
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'dark-theme');
  }

  bapTopicItemNavigationUrl(type: string, identifier: string) {
    switch (type.toUpperCase()) {
      case 'ARTICLE':
        return `bap-articles/${identifier}`;
      case 'WORKSHOP':
        return `bap-workshop-details/${identifier}`;
      case 'FLASH CARD':
      default:
        return '#';
    }
  }

  openBapFlashCardModal(data: { identifier: string, tier: string }) {
    // Check tier access
    const canViewContent = this.contentService.canViewContent(data.tier);
    if (!canViewContent && !this.configService.isPreview) {
      this.toastr.error('Partner does not have tier required to access the content.');
      return;
    }

    const modalRef = this.modalService.open(BapFlashCardModalComponent, {
      centered: true,
      windowClass: 'bap-flash-card-modal',
      size: 'xl'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.id = data.identifier;
  }

  openUnlockBAPContentModal() {
    const modalRef = this.modalService.open(UnlockBapContentModalComponent, {
      centered: true,
      windowClass: 'unlock-bap-content-modal',
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
  }
}